import {
  REGSCHL_HESSEN,
  SERVER_URL_SUCHE,
  SERVER_URL_SUCHE_KEIN_ERGEBNIS_STATISTIC,
  SERVER_URL_SUCHE_ORG,
} from '../../constants';
import type { State } from '../../state/createInitialState';
import { deepEquals } from '../../util/deepEquals';
import { logError } from '../../util/logError';
import { requestWithoutTypes } from '../../util/requestWithoutTypes';
import { UnreachableCaseError } from '../../util/UnreachableCaseError';
import { Screen } from '../../view';
import {
  isAbortError,
  isErrorWithStatus,
  postNoRedirect,
  prepareData,
} from '../util/fetch';
import { fetchStatistics } from '../util/fetchStatistics';
import { focusSearchfilter } from '../util/focusSeiteninhalt';
import { abortSearches } from './searchCommon';
import { SEARCH_ERROR, SEARCH_FINISHED, SEARCH_STARTED } from './searchEvents';
import type {
  SearchRequest,
  SearchResultFromServer,
  ServerSearchRequest,
} from './searchTypes';
import { SearchResultType } from './searchTypes';
import { requestWithoutOrgFilter } from '../../util/requestWithoutOrgFilter';
import { extractSearchRequest } from '../../view/read/extractSearchRequest';
import type { SimpleStore } from '../../state/SimpleStore';
import { requestWithoutPvLage } from '../../util/requestWithoutPvLage';
import { PV_LAGEN_LOADED } from '../../reducer/pvLagenEvents';

/**
 * Sucht nach Suchbegriff in bestimmtem Ort.
 */
export function searchIfNecessary(
  simpleStore: SimpleStore,
  hasFrame: boolean,
): void {
  const state = simpleStore.getState();
  const noCities = state.screen === Screen.OrgSuche;
  const forceTypeFilter = state.screen === Screen.OrgSuche;

  const forRequest = extractSearchRequest(state);

  // in der Dienststellensuche soll keine Suche gestartet werden, wenn weder Ort noch Suchbegriff vorhanden sind
  if (
    state.screen === Screen.OrgSuche &&
    forRequest.regschl === REGSCHL_HESSEN &&
    !state.search.request.searchTerm
  ) {
    if (hasFrame) {
      return;
    } else {
      // Workaround für BAVWP-1190; mit BAVWP-1266 wieder entfernen
      window.location.reload();
    }
  }

  abortSearches(simpleStore);

  const skipTypesCount =
    state.screen === Screen.OrgSuche &&
    Boolean(
      state.search.results.data &&
        deepEquals(
          requestWithoutTypes(state.search.results.forRequest),
          requestWithoutTypes(forRequest),
        ),
    );
  const skipOrgFilterCount =
    state.screen === Screen.OrgSuche &&
    Boolean(state.search.results.data?.orgFilterCounts) &&
    deepEquals(
      requestWithoutOrgFilter(state.search.results.forRequest),
      requestWithoutOrgFilter(forRequest),
    );
  const skipPvLagenCount =
    state.screen === Screen.OrgSuche ||
    (Boolean(state.search.results.data?.pvLagenCounts) &&
      deepEquals(
        requestWithoutPvLage(state.search.results.forRequest),
        requestWithoutPvLage(forRequest),
      ));
  const skipSearch =
    state.search.results.data &&
    deepEquals(state.search.results.forRequest, forRequest);

  if (!skipSearch) {
    doSearch(
      state,
      noCities,
      skipOrgFilterCount,
      skipPvLagenCount,
      skipTypesCount,
      forceTypeFilter,
      simpleStore,
      forRequest,
      hasFrame,
    );
  }
}

function doSearch(
  state: State,
  noCities: boolean,
  skipOrgFilterCount: boolean,
  skipPvLagenCount: boolean,
  skipTypesCount: boolean,
  forceTypeFilter: boolean,
  simpleStore: SimpleStore,
  forRequest: SearchRequest,
  hasFrame: boolean,
) {
  let url;
  switch (state.screen) {
    case Screen.Bereich:
    case Screen.Lage:
    case Screen.Sublage:
    case Screen.Suche:
    case Screen.Startseite:
      url = SERVER_URL_SUCHE();
      break;
    case Screen.OrgSuche:
      url = SERVER_URL_SUCHE_ORG();
      break;
    case Screen.Leistung:
    case Screen.Org:
    case Screen.Information:
      return;
    default:
      throw new UnreachableCaseError(state.screen);
  }

  const data: ServerSearchRequest = {
    get_pv_lagen: !state.pvLagen.pvLagenByCode,
    lang: forRequest.lang,
    no_cities: noCities,
    regschl: forRequest.regschl,
    regschl_wahl: forRequest.regschlWahl,
    skip_org_filter_count: skipOrgFilterCount,
    skip_pv_lagen_count: skipPvLagenCount,
    skip_types_count: skipTypesCount,
    force_type_filter: forceTypeFilter,
    suche: forRequest.searchTerm,
    typ: forRequest.filter.type,
    pv_lage: forRequest.filter.pvLage,
    gebietsebene: forRequest.gebietsebene,
    regschl_suchtyp: forRequest.regschlSuchTyp,
    skip_frame: hasFrame,
  };
  const getParameters = {
    url,
    data,
    flags: state.flags,
  };

  const { abortRequest, result } = postNoRedirect<
    ServerSearchRequest,
    SearchResultFromServer
  >(getParameters);
  simpleStore.dispatch(
    SEARCH_STARTED({
      abortRequest,
      forRequest,
    }),
  );
  const callback = result.then((results: SearchResultFromServer) => {
    const latestRequest =
      simpleStore.getState().abortRequest.search?.forRequest;
    if (deepEquals(latestRequest, forRequest)) {
      simpleStore.dispatch(
        SEARCH_FINISHED({
          forRequest,
          resultHasFrame: !hasFrame, // wenn aktuell kein Rahmen existiert wird der Rahmen mit diesem Request abgefragt
          results,
        }),
      );
      callStatisticIfNoResult(results, getParameters);
    }
    if (results.pvLagenByCode) {
      simpleStore.dispatch(PV_LAGEN_LOADED(results.pvLagenByCode));
    }
  });
  focusSearchfilter();

  callback.catch((e: unknown) => {
    const latestRequest =
      simpleStore.getState().abortRequest.search?.forRequest;
    if (deepEquals(latestRequest, forRequest)) {
      // request war nicht erfolgreich
      if (isAbortError(e)) {
        // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
      } else if (isErrorWithStatus(e) && e.status === 404) {
        // keine Daten gefunden
        simpleStore.dispatch(SEARCH_ERROR(e));
      } else {
        // sonstiger Fehler
        logError(e);
        simpleStore.dispatch(SEARCH_ERROR(e));
      }
    }
  });
}

function callStatisticIfNoResult(
  results: SearchResultFromServer,
  getParameters: { url: string; data: ServerSearchRequest },
) {
  if (results.type === SearchResultType.RESULT) {
    const rows = results.result;
    if (
      !rows.leistungenRows?.length &&
      !rows.orgeinheitenRows?.length &&
      !rows.editorialRows?.length
    ) {
      const data = prepareData(getParameters.data);
      if (data) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetchStatistics({
          url: SERVER_URL_SUCHE_KEIN_ERGEBNIS_STATISTIC(),
          data,
        });
      }
    }
  }
}
