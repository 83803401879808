/* eslint-disable no-param-reassign */
import type { Draft } from 'immer';
import {
  getPatch,
  getScreenRequest,
  markPatchAsCurrentIfExists,
} from '../lib/htmlCache';
import type { State } from '../state/createInitialState';
import { resetOdMapState } from './resetOdMapState';

export function reduceForScreenChange(draft: Draft<State>) {
  markPatchAsCurrentIfExists(draft);
  resetOdMapState(
    draft,
    getPatch(draft, getScreenRequest(draft))?.pageElements.odMapDomIds ?? [],
  );
}
