/* eslint-disable no-param-reassign */
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  removeAbortRequest,
  type State,
  storeAbortRequest,
} from '../state/createInitialState';
import {
  MAP_CITY_CHANGED,
  MAP_CITY_INPUT_CHANGED,
  MAP_COUNTS_LOADING_ERROR,
  MAP_COUNTS_LOADING_FINISHED,
  MAP_COUNTS_LOADING_STARTED,
  MAP_DATA_LOADING_ERROR,
  MAP_DATA_LOADING_FINISHED,
  MAP_DATA_LOADING_STARTED,
  MAP_DETAILS_SHOWN,
  MAP_DROPDOWN_HIDDEN,
  MAP_DROPDOWN_SELECTED,
  MAP_DROPDOWN_SHOWN,
} from './mapEvents';
import { COMMON__HESSEN } from '../texts';

export function createMapReducer(builder: ActionReducerMapBuilder<State>) {
  builder.addCase(MAP_COUNTS_LOADING_ERROR, (state) => {
    removeAbortRequest(state, 'mapOnlineServicesCounts');
    state.mapOnlineServices.dataError = true;
  });
  builder.addCase(MAP_COUNTS_LOADING_FINISHED, (state, action) => {
    const mapCounts = action.payload;
    removeAbortRequest(state, 'mapOnlineServicesCounts');
    state.mapOnlineServices.onlineServicesByRegschl = mapCounts;
  });
  builder.addCase(MAP_COUNTS_LOADING_STARTED, (state, action) => {
    const { abortRequest } = action.payload;
    storeAbortRequest(state, 'mapOnlineServicesCounts', {
      abortRequest,
      forRequest: undefined,
    });
  });
  builder.addCase(MAP_DATA_LOADING_ERROR, (state) => {
    removeAbortRequest(state, 'mapOnlineServicesData');
    state.mapOnlineServices.dataError = true;
  });
  builder.addCase(MAP_DATA_LOADING_FINISHED, (state, action) => {
    const mapData = action.payload;
    removeAbortRequest(state, 'mapOnlineServicesData');
    state.mapOnlineServices.mapData = mapData;
  });
  builder.addCase(MAP_DATA_LOADING_STARTED, (state, action) => {
    const { abortRequest } = action.payload;
    storeAbortRequest(state, 'mapOnlineServicesData', {
      abortRequest,
      forRequest: undefined,
    });
  });
  builder.addCase(MAP_DROPDOWN_SHOWN, (state, action) => {
    const mapDomId = action.payload;
    const mapState = state.mapOnlineServices.mapStates[mapDomId];
    if (mapState) {
      mapState.dropdown.visible = true;
    }
  });
  builder.addCase(MAP_DETAILS_SHOWN, (state, action) => {
    const mapDomId = action.payload;
    const mapState = state.mapOnlineServices.mapStates[mapDomId];
    if (mapState) {
      mapState.detailsShown = true;
    }
  });
  builder.addCase(MAP_DROPDOWN_HIDDEN, (state, action) => {
    const mapDomId = action.payload;
    const mapState = state.mapOnlineServices.mapStates[mapDomId];
    if (mapState) {
      mapState.dropdown.visible = false;
    }
  });
  builder.addCase(MAP_DROPDOWN_SELECTED, (state, action) => {
    const { mapDomId, index } = action.payload;
    const mapState = state.mapOnlineServices.mapStates[mapDomId];
    if (mapState) {
      mapState.dropdown.selection = index;
    }
  });
  builder.addCase(MAP_CITY_INPUT_CHANGED, (state, action) => {
    const { mapDomId, input } = action.payload;
    const mapState = state.mapOnlineServices.mapStates[mapDomId];
    if (mapState) {
      mapState.input.searchTerm = input;
      mapState.dropdown.selection = undefined;
    }
  });
  builder.addCase(MAP_CITY_CHANGED, (state, action) => {
    const { mapDomId, regschl } = action.payload;
    const mapState = state.mapOnlineServices.mapStates[mapDomId];
    if (mapState) {
      if (state.flags.map_od === 'synced') {
        state.citySearch.selectedOrt = {
          id: regschl,
          land: COMMON__HESSEN,
          isFindable: true,
          loadNeeded: true,
        };
      } else {
        mapState.selectedRegschl = regschl;
      }
      mapState.input.searchTerm = undefined;
      mapState.detailsShown = false;
      mapState.dropdown.visible = false;
      mapState.dropdown.selection = undefined;
    }
  });
}
