import { mapValues } from '../lib/lodash';
import { asyncPolyfillRegExp } from '../polyfills';
import { logError } from '../util/logError';
import {
  ORT__FEEDBACK__EINGABE_FEHLT,
  SUCHE__FEEDBACK__EINGABE_FEHLT,
} from '../texts';

const searchValidations = {
  searchTerm: required(SUCHE__FEEDBACK__EINGABE_FEHLT),
};

const citySearchValidations = {
  searchTerm: required(ORT__FEEDBACK__EINGABE_FEHLT),
};

export type ValidationResult<K extends string> = Record<
  K,
  { error: string | undefined; value: string }
>;

type SearchInputParams = {
  input: { searchTerm: string };
  showValidationErrors: boolean;
};
export function validateSearchInput({
  input,
  showValidationErrors,
}: SearchInputParams): ValidationResult<keyof SearchInputParams['input']> {
  const validations = searchValidations;
  return validate({ data: input, showValidationErrors, validations });
}

type SearchingResultParams = { searchTerm: string };
export function isValidForSearchingResult(
  request: SearchingResultParams,
): boolean {
  return isValid({ data: request, validations: searchValidations });
}

export function isValidForCitySearch(searchTerm: string): boolean {
  return isValid({ data: { searchTerm }, validations: citySearchValidations });
}

type IsValidParams = {
  data: Partial<Record<string, string>>;
  validations: Partial<Record<string, ValidationFunction>>;
};
function isValid({ data, validations }: IsValidParams): boolean {
  return Object.keys(validations).every((key) => {
    const validation = validations[key];
    const value = data[key];
    return !(validation && value !== undefined && validation(value).error);
  });
}

type ValidateParams<D extends string> = {
  data: Record<D, string>;
  showValidationErrors: boolean;
  validations: Record<D, ValidationFunction>;
};
function validate<D extends string>({
  data,
  showValidationErrors,
  validations,
}: ValidateParams<D>): ValidationResult<D> {
  return mapValues((validationFn: ValidationFunction, key: D) => {
    const value = data[key];
    if (showValidationErrors) {
      return validationFn(value);
    } else {
      return noValidation(value);
    }
  })(validations);
}

function noValidation(value: string) {
  return {
    error: undefined,
    value,
  };
}

let REGEXP_LETTER = /none/;
// eslint-disable-next-line i18next/no-literal-string
asyncPolyfillRegExp('[\\p{L}\\p{N}]', 'u')
  .then((regex) => {
    REGEXP_LETTER = regex;
  })
  .catch((error: unknown) => {
    console.error('Error during polyfilling RegExp:', error);
    logError(error);
  });

type ValidationFunction = (value: string) => {
  error: undefined | string;
  value: string;
};
function required(errorText: string): ValidationFunction {
  return (value: string) => {
    if (value && value.match(REGEXP_LETTER)) {
      return {
        error: undefined,
        value,
      };
    } else {
      return { error: errorText, value };
    }
  };
}
