import { Dispatch } from 'redux';
import { loadAndOpenChatbot } from '../../../action/chatbot/loadAndOpenChatbot';
import type { RenderResultWithSsr } from '../../../lib/renderTypes';
import type { ChatbotScriptSrc } from '../../../constants';
import type {
  ChatbotState,
  HtmlCacheEntryData,
} from '../../../state/createInitialState';
import { Flags } from '../../../history/flags';
import { renderChatbotButtonUnloaded } from './renderChatbotButtonUnloaded';
import { renderChatbotButtonLoaded } from './renderChatbotButtonLoaded';
import { renderChatbotAskPrivacyButton } from './renderChatbotAskPrivacyButton';

function createHandleChatbotButtonClicked(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
  chatbotSelectors: string[],
) {
  return function handleChatbotButtonClicked(this: HTMLElement) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadAndOpenChatbot(dispatch, flags, chatbotScriptSrc, chatbotSelectors);
  };
}

function checkPrivacySettingsAndRenderChatbotButton(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
  chatbotState: ChatbotState,
  chatbotSafeHtml: string,
  chatbotSelectors: string[],
): RenderResultWithSsr | undefined {
  if (chatbotState.privacyAccepted) {
    if (chatbotState.loaded) {
      return renderChatbotButtonLoaded(chatbotSafeHtml);
    } else {
      return renderChatbotButtonUnloaded(
        chatbotState,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        createHandleChatbotButtonClicked(
          dispatch,
          flags,
          chatbotScriptSrc,
          chatbotSelectors,
        ),
      );
    }
  } else {
    return renderChatbotAskPrivacyButton(
      dispatch,
      flags,
      chatbotScriptSrc,
      chatbotSelectors,
    );
  }
}

export function renderChatbot(
  dispatch: Dispatch,
  flags: Flags,
  cacheEntry: HtmlCacheEntryData,
  chatbotScriptSrc: ChatbotScriptSrc | undefined,
  chatbotState: ChatbotState,
  chatbotSafeHtml: string | undefined,
  chatbotSelectors: string[],
): RenderResultWithSsr | undefined {
  if (
    chatbotScriptSrc &&
    // chatbotSafeHtml &&
    cacheEntry.patch.pageElements.showChatbot
  ) {
    return checkPrivacySettingsAndRenderChatbotButton(
      dispatch,
      flags,
      chatbotScriptSrc,
      chatbotState,
      chatbotSafeHtml ?? '',
      chatbotSelectors,
    );
  } else {
    return undefined;
  }
}
