import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { div, form, section } from '../../render/html';
import { filter } from '../blocks/filter';
import { renderSearchPageSearchField, searchResults } from '../blocks/search';
import type { SimpleStore } from '../../state/SimpleStore';
import h from '../../render/incremental-hyperscript';
import { share } from '../share';
import { ShareTemplate, SUCHE__BEREICH__ARIA } from '../../texts';
import { Icons } from '../elements/icon';

export default function mainContent(
  simpleStore: SimpleStore,
  shareLinkDefinitions: ShareTemplate[],
  iconsSafeHtml: Icons,
): RenderResultWithSsr {
  const state = simpleStore.getState();
  return h.fragment([
    share(
      state.flags.share_generic === 'show',
      {
        title: document.title,
        url: String(document.location),
      },
      shareLinkDefinitions,
      iconsSafeHtml,
    ),
    div(
      { key: 'dynamic' },
      div({}, [
        form(
          {
            onsubmit: (event: Event) => {
              event.preventDefault();
            },
          },
          [
            section(
              {
                key: 'screen-search-search',
                'aria-label': SUCHE__BEREICH__ARIA,
                class: 'og-search-search',
              },
              renderSearchPageSearchField(simpleStore),
            ),
            // eslint-disable-next-line i18next/no-literal-string
            filter('screen-search-filter', 'og-search-filter', simpleStore),
          ],
        ),
        div(
          {
            key: 'screen-search-content',
            class: 'og-search-result',
          },
          searchResults(simpleStore, iconsSafeHtml),
        ),
      ]),
    ),
  ]);
}
