import { produce } from 'immer';
// eslint-disable-next-line import/no-unresolved,import/extensions
import type { ModuleNamespace } from 'vite/types/hot.d.ts';
import type { HtmlPatch, HtmlPatchBereich } from './action/htmlTypes';
import { loadDataIfNecessary as hmrLoadDataIfNecessary } from './action/loadDataIfNecessary';
import { handleCityChanged as hmrHandleCityChanged } from './action/search/handleCityChanged';
import {
  handleSearchChanged as hmrHandleSearchChanged,
  handleStartpageSearchChanged as hmrHandleStartpageSearchChanged,
} from './action/search/handleSearchChanged';
import HmrMyHistory, {
  modifyInitialURL as hmrModifyInitialURL,
} from './history/myHistoryApi';
import hmrMyHistoryImpl, { PARAM_FLAGS } from './history/myHistoryImpl';
import { getScreenRequest as hmrGetScreenRequest } from './lib/htmlCache';
import hmrCreateRootReducer from './reducer/createRootReducer';
import { APP_UPDATED as HMR_APP_UPDATED } from './reducer/events';
import { cacheInitialHtml as hmrCacheInitialHtml } from './render/cached-incremental-dom';
import hmrCreateStore from './render/createStore';
import type { State, HtmlCacheEntryData } from './state/createInitialState';
import hmrCreateInitialState from './state/createInitialState';
import { logError as hmrLogError } from './util/logError';
import { Screen as HmrScreen } from './view';
import hmrRenderMain from './view/main';
import { highlightCurrentMenu as hmrHighlightCurrentMenu } from './view/render/highlightCurrentMenu';
import hmrHandleInputChangedEvent from './action/util/handleInputChangedEvent';
import { reduceForNewPatch as hmrReduceForNewPatch } from './reducer/reduceForNewPatch';
import type { SimpleStore } from './state/SimpleStore';
import { renderOnlinediensteMapSection as hmrRenderMap } from './map_online_services/renderOnlinediensteMapSection';
import type {
  ScreenRequest,
  ScreenRequestBereich,
} from './action/common/commonRequestTypes';
import {
  onChatbotPrivacyAccepted as hmrOnChatbotPrivacyAccepted,
  onNfkPrivacyAccepted as hmrOnNfkPrivacyAccepted,
} from './state/sessionStore';
import { NFK_PRIVACY_ACCEPTED as HMR_NFK_PRIVACY_ACCEPTED } from './reducer/nfkEvents';
import { ID_FEEDBACK_BUTTON as HMR_ID_FEEDBACK_BUTTON } from './view/blocks/nfk/renderFeedbackButton';
import { CHATBOT_PRIVACY_ACCEPTED as HMR_CHATBOT_PRIVACY_ACCEPTED } from './reducer/chatbotEvents';

// für Hot-Module-Replacement
let loadDataIfNecessary = hmrLoadDataIfNecessary;
let handleCityChanged = hmrHandleCityChanged;
let handleSearchChanged = hmrHandleSearchChanged;
let handleStartpageSearchChanged = hmrHandleStartpageSearchChanged;
let MyHistory = HmrMyHistory;
let modifyInitialURL = hmrModifyInitialURL;
let myHistoryImpl = hmrMyHistoryImpl;
let getScreenRequest = hmrGetScreenRequest;
let createRootReducer = hmrCreateRootReducer;
let APP_UPDATED = HMR_APP_UPDATED;
let cacheInitialHtml = hmrCacheInitialHtml;
let createStore = hmrCreateStore;
let createInitialState = hmrCreateInitialState;
let logError = hmrLogError;
let Screen = HmrScreen;
let renderMain = hmrRenderMain;
let highlightCurrentMenu = hmrHighlightCurrentMenu;
let handleInputChangedEvent = hmrHandleInputChangedEvent;
let reduceForNewPatch = hmrReduceForNewPatch;
let renderMap = hmrRenderMap;
let onChatbotPrivacyAccepted = hmrOnChatbotPrivacyAccepted;
let onNfkPrivacyAccepted = hmrOnNfkPrivacyAccepted;
let NFK_PRIVACY_ACCEPTED = HMR_NFK_PRIVACY_ACCEPTED;
let ID_FEEDBACK_BUTTON = HMR_ID_FEEDBACK_BUTTON;
let CHATBOT_PRIVACY_ACCEPTED = HMR_CHATBOT_PRIVACY_ACCEPTED;

let simpleStore: SimpleStore;
let myHistory: HmrMyHistory;

function initApp(): void {
  initHistory();
  // first initialization of store
  initStore();
  addGlobalListeners();
}

/**
 * Grundstruktur der Anwendung:
 *
 * State = Zustand der Anwendung (inklusive aktueller Werte aller Eingabefelder)<p>
 * View = Darstellung der Anwendung (am Anfang jeder View werden die notwendigen
 *        Werte aus dem State gelesen)<p>
 * Actions = Aktionen, die ausgeführt werden können (werden von Events aus den
 *           Views heraus ausgerufen)<p>
 *           Die Aktionsnamen sind als Tätigkeit formuliert<p>
 * Events = Ereignisse, die aus den Aktionen resultieren<p>
 *          Die Ereignisnamen sind in der Vergangenheitsform formuliert, um
 *          auszudrücken, dass diese nicht blockiert werden können (nur Aktionen
 *          können blockiert werden; die Aktion kann entscheiden, ob und falls
 *          ja, welche Events erzeugt werden sollen)<p>
 * Reducer = Verarbeitung der Ereignisse<p>
 *           Diese dürfen keine Nebeneffekte haben<p>
 *           Das heißt gleicher alter Zustand + gleiches Ereignis mit gleichen
 *           Daten muss immer den gleichen neuen Zustand ergeben.<p>
 *
 * Anleitung für Veränderungen:<p>
 * * Änderungen in der Anzeige: immer in Views<p>
 * * Geschäftslogik: immer in Aktionen. Vor allem Serveraufrufe müssen immer in
 *   Aktionen stattfinden<p>
 * * Veränderungen des Zustands, die aus einer Aktion resultieren: entweder
 *   vorhandene Ereignisse nutzen oder neue definieren und entsprechend
 *   Reducer anpassen oder neue schreiben<p>
 * * Neue Eingabefelder: State erweitern, Aktion ergänzen, View erweitern<p>
 * * Neue Buttons: Aktionen ergänzen, View erweitern<p>
 *
 * Problembehebung:<p>
 * * falls beim dynamischen Aus- und Einblenden von Elementen seltsame Effekte
 *   auftreten, sollte den Elementen in der Liste jeweils eine Eigenschaft "key"
 *   mitgegeben werden, die eindeutig ist. Über diese kann Incremental Dom
 *   wo Elemente eingefügt oder gelöscht wurden.
 *
 *   Beispiel:
 *   [
 *     div({key: 'inputName'}, [
 *       label({}, 'Name'),
 *       input({}),
 *     ]),
 *     showCity ? div({key: 'inputCity'}, [
 *       label({}, 'Stadt'),
 *       input({}),
 *     ]) : undefined,
 *     div({key: 'inputEmail'}, [
 *       label({}, 'E-Mail'),
 *       input({}),
 *     ]),
 *   ]
 *
 *   Siehe hierzu auch:
 *   http://google.github.io/incremental-dom/#keys-and-arrays
 */

/*
let triggered = false;
function handleScroll(...args: any[]) {
  if (!triggered) {
    triggered = true;
    window.requestAnimationFrame(() => {
      triggered = false;
      console.log(args);
    });
  }
}

window.addEventListener('scroll', handleScroll);
*/

function callNationalFeedbackComponent() {
  document.getElementById(ID_FEEDBACK_BUTTON)?.click();
}

/**
 * Initializes the app at element "element"
 * @param appUpdatedPromise will be resolved if the app was updated (and a reload is needed)
 */
export default function app(appUpdatedPromise: Promise<void>): void {
  initApp();

  if (window.jsHandover) {
    const { inputs, pendingActions } = window.jsHandover();

    Object.values(inputs)
      // Inputs klonen, da die Verarbeitung das Rendern auslöst, was Attribute überschreibt
      .map((input) => input.cloneNode() as HTMLInputElement)
      .forEach((input) => {
        const type = input.dataset.hzdInput;
        switch (type) {
          case 'city': {
            const { screen } = simpleStore.getState();
            const onlyHessen = screen === Screen.OrgSuche;
            const city = handleInputChangedEvent(input);
            handleCityChanged(
              simpleStore,
              city,
              undefined, // wird nur nach Auswahl aus dem Dropdown benötigt
              onlyHessen,
            );
            break;
          }
          case 'search': {
            const { screen } = simpleStore.getState();
            switch (screen) {
              case Screen.Startseite:
                handleStartpageSearchChanged(simpleStore, input);
                break;
              default:
                handleSearchChanged(simpleStore, input);
                break;
            }
            break;
          }
          default:
            logError(
              new Error(
                `Unknown input type ${type ?? 'undefined'} on ${input.id}`,
              ),
            );
        }
      });

    pendingActions.forEach((pendingAction) => {
      switch (pendingAction.key) {
        case 'feedback':
          callNationalFeedbackComponent();
          break;
        case 'navigate': {
          const url = new URL(pendingAction.href, window.location.href);
          window.history.pushState(undefined, '', url);
          break;
        }
        case 'search':
          loadDataIfNecessary(simpleStore);
          break;
        default: {
          // noinspection UnnecessaryLocalVariableJS
          const pendingActionUnreachable: never = pendingAction;
          logError(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            new Error(`Unknown pending action ${pendingActionUnreachable}`),
          );
          break;
        }
      }
    });
  }

  render();
  highlightCurrentMenu();
  if (window.removeOverlay) {
    window.removeOverlay();
  }

  appUpdatedPromise
    .then(() => {
      simpleStore.dispatch(APP_UPDATED());
    })
    .catch(() => {
      // ignore errors
    });
}

/**
 * Initializes history object
 */
function initHistory() {
  // myHistory ist beim ersten Aufruf undefined
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (myHistory) {
    myHistory.disconnect();
  }
  myHistory = new MyHistory(myHistoryImpl);
  modifyInitialURL();
}

function setInitialHtml(initialHtml: HtmlCacheEntryData, state: State): State {
  return produce(state, (draft) => {
    // const screenDataAndRequest = getScreenRequest(state);
    // if (screenDataAndRequest) {
    reduceForNewPatch(draft, initialHtml);
    // }
  });
}

function createHtmlCacheEntryData(
  patch: HtmlPatch,
  screenRequest: ScreenRequest,
): HtmlCacheEntryData | undefined {
  if (screenRequest.screen === patch.data.screen) {
    return {
      // Bereich stimmt zwar nicht immer, aber ich habe keine bessere
      // Möglichkeit gefunden, TypeScript zu sagen, dass patch und screenRequest
      // für denselben Screen sind
      patch: patch as HtmlPatchBereich,
      screenRequest: screenRequest as ScreenRequestBereich,
    };
  } else {
    return undefined;
  }
}

/**
 * Create store
 *
 * @param previousState (optional) the state to set instead of creating a new initial state
 * For hot-module-replacement
 */
function initStore(previousState?: State) {
  let stateToSet: State;
  if (previousState) {
    stateToSet = previousState;
  } else {
    const initialHtml = cacheInitialHtml();
    const initialState = myHistory.initialState(
      createInitialState(initialHtml.patch.data.screen),
      {
        data: initialHtml.serverData,
        patch: initialHtml.patch,
      },
    );
    stateToSet = {
      ...initialState,
      htmlFrame: initialHtml.frame,
    };
    const cacheEntryData = createHtmlCacheEntryData(
      initialHtml.patch,
      getScreenRequest(stateToSet),
    );
    if (cacheEntryData) {
      stateToSet = setInitialHtml(cacheEntryData, stateToSet);
    }
  }
  const rootReducer = createRootReducer(stateToSet);

  const createStoreResult = createStore(rootReducer, myHistory);
  simpleStore = createStoreResult.simpleStore;
  createStoreResult.store.subscribe(() => {
    render();
  });
  if (!previousState) {
    myHistory.loadInitialDataAndSetUrl();
  }
}

function addGlobalListeners() {
  if (window.vwpGlobalUnregistration) {
    window.vwpGlobalUnregistration.forEach((fun) => {
      fun();
    });
  }
  window.vwpGlobalUnregistration = [];

  const bodyClickListener = (event: MouseEvent) => {
    if (event.target) {
      const target = event.target as Element;
      const elem: HTMLAnchorElement | HTMLButtonElement | null =
        // eslint-disable-next-line i18next/no-literal-string
        target.closest('a,button');
      if (elem?.tagName.toLowerCase() === 'a') {
        const link = elem as HTMLAnchorElement;
        const { href } = link;
        const indexOfHash = href.indexOf('#');
        const hrefWithoutHash =
          indexOfHash < 0 ? href : href.substring(0, indexOfHash);
        const currentLoc = window.location;
        const currentLocationHost = `${currentLoc.protocol}//${currentLoc.host}`;
        const currentLocationWithoutHash =
          currentLocationHost + currentLoc.pathname + currentLoc.search;
        if (
          // if link targets the same host
          href.startsWith(currentLocationHost) &&
          // and is different from current path
          currentLocationWithoutHash !== hrefWithoutHash
        ) {
          const hrefWithFlags = new URL(href, window.location.href);
          const currentFlags = new URLSearchParams(window.location.search).get(
            'flags',
          );
          if (currentFlags !== null) {
            hrefWithFlags.searchParams.set(PARAM_FLAGS, currentFlags);
          }

          if (simpleStore.navigate(hrefWithFlags)) {
            event.preventDefault();
          }
        }
      }
    }
  };
  document.body.addEventListener('click', bodyClickListener);
  window.vwpGlobalUnregistration.push(() => {
    document.body.removeEventListener('click', bodyClickListener);
  });

  // beim Laden aus dem BFCache wird der State nicht korrekt gerendert
  const windowPageshowListener = (ev: PageTransitionEvent) => {
    if (ev.persisted) {
      // hier müssen ggf. als Workaround die Inhalte der einzelnen Elemente aus cache-incremental-dom geleert werden
      // render();

      // TODO: Workaround entfernen, sobald Browser-Back repariert wurde
      window.location.reload();
    }
  };
  window.addEventListener('pageshow', windowPageshowListener);
  window.vwpGlobalUnregistration.push(() => {
    window.removeEventListener('pageshow', windowPageshowListener);
  });
}

// marker to abort timeout for IE rendering workaround
/*
let ieWorkaroundTimeout;
function ieWorkaround() {
  if (ieWorkaroundTimeout) {
    window.clearTimeout(ieWorkaroundTimeout);
    // window.clearImmediate(ieWorkaroundTimeout);
    ieWorkaroundTimeout = undefined;
  }
  ieWorkaroundTimeout = setTimeout(ieForceRedraw, 500);
  // ieWorkaroundTimeout = setImmediate(ieForceRedraw);
}
  */

/**
 * Write the DOM tree for the current state of the application
 */
function render() {
  try {
    renderMain(simpleStore);
    renderMap(simpleStore);
  } catch (e) {
    if (e instanceof Error) {
      logError(e);
    } else {
      logError(new Error(String(e)));
    }
  }
}

// //////////////////////////////////////////////////
// handling session store changes in other tabs/windows

function handleChatbotPrivacyAcceptedInOtherTab() {
  if (!simpleStore.getState().chatbot.privacyAccepted) {
    simpleStore.dispatch(CHATBOT_PRIVACY_ACCEPTED());
  }
}

onChatbotPrivacyAccepted(handleChatbotPrivacyAcceptedInOtherTab);

function handleNfkPrivacyAcceptedInOtherTab() {
  if (!simpleStore.getState().nfk.privacyAccepted) {
    simpleStore.dispatch(NFK_PRIVACY_ACCEPTED());
  }
}

onNfkPrivacyAccepted(handleNfkPrivacyAcceptedInOtherTab);

// //////////////////////////////////////////////////
// support hot module replacement in development mode

function createHot(
  level: 'render' | 'store' | 'history',
  path: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: (newModule: ModuleNamespace) => void,
) {
  return (newModule: ModuleNamespace | undefined) => {
    console.log(
      `handling hot module replacement, type: ${level}, path: ${path}`,
    );
    if (newModule) {
      handler(newModule);
    }
    if (level === 'store' || level === 'history') {
      if (level === 'history') {
        initHistory();
      }
      initStore(simpleStore.getState());
    }
    render();
  };
}

if (import.meta.hot) {
  console.log('adding hot module functions for app.ts');
  // damit das Folgende funktioniert, muss exakt der String "import.meta.hot.accept" verwendet werden.
  // andernfalls erkennt vite den Eintrag nicht
  import.meta.hot.accept(
    './action/loadDataIfNecessary',
    createHot('store', './action/loadDataIfNecessary', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      loadDataIfNecessary = hmrNew.loadDataItNecessary;
    }),
  );
  import.meta.hot.accept(
    './action/search/handleCityChanged',
    createHot('store', './action/search/handleCityChanged', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      handleCityChanged = hmrNew.handleCityChanged;
    }),
  );
  import.meta.hot.accept(
    './action/search/handleSearchChanged',
    createHot('store', './action/search/handleSearchChanged', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      handleSearchChanged = hmrNew.handleSearchChanged;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      handleStartpageSearchChanged = hmrNew.handleStartpageSearchChanged;
    }),
  );
  import.meta.hot.accept(
    './history/myHistoryApi',
    createHot('history', './history/myHistoryApi', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      MyHistory = hmrNew.default;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      modifyInitialURL = hmrNew.modifyInitialURL;
    }),
  );
  import.meta.hot.accept(
    './history/myHistoryImpl',
    createHot('history', './history/myHistoryImpl', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      myHistoryImpl = hmrNew.default;
    }),
  );
  import.meta.hot.accept(
    './lib/htmlCache',
    createHot('store', './lib/htmlCache', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      getScreenRequest = hmrNew.getScreenRequest;
    }),
  );
  import.meta.hot.accept(
    './reducer/createRootReducer',
    createHot('store', './reducer/createRootReducer', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      createRootReducer = hmrNew.createRootReducer;
    }),
  );
  import.meta.hot.accept('./reducer/events', (hmrNew) => {
    if (hmrNew) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      APP_UPDATED = hmrNew.APP_UPDATED;
    }
  });
  import.meta.hot.accept(
    './render/cached-incremental-dom',
    createHot('store', './render/cached-incremental-dom', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      cacheInitialHtml = hmrNew.cacheInitialHtml;
    }),
  );
  import.meta.hot.accept(
    './render/createStore',
    createHot('store', './render/createStore', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      createStore = hmrNew.default;
    }),
  );
  import.meta.hot.accept(
    './state/createInitialState',
    createHot('store', './state/createInitialState', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      createInitialState = hmrNew.default;
    }),
  );
  import.meta.hot.accept(
    './util/logError',
    createHot('store', './util/logError', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      logError = hmrNew.logError;
    }),
  );
  import.meta.hot.accept(
    './view',
    createHot('render', './view', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      Screen = hmrNew.Screen;
    }),
  );
  import.meta.hot.accept(
    './view/main',
    createHot('render', './view/main', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      renderMain = hmrNew.default;
    }),
  );
  import.meta.hot.accept(
    './view/render/hightlightCurrentMenu',
    createHot('render', './view/render/hightlightCurrentMenu', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      highlightCurrentMenu = hmrNew.highlightCurrentMenu;
    }),
  );
  import.meta.hot.accept(
    './action/util/handleInputChangedEvent',
    createHot('render', './action/util/handleInputChangedEvent', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      handleInputChangedEvent = hmrNew.handleInputChangedEvent;
    }),
  );
  import.meta.hot.accept(
    './reducer/reduceForNewPatch',
    createHot('render', './reducer/reduceForNewPatch', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      reduceForNewPatch = hmrNew.reduceForNewPatch;
    }),
  );
  import.meta.hot.accept(
    './map_online_services/renderOnlinediensteMapSection',
    createHot(
      'render',
      './map_online_services/renderOnlinediensteMapSection',
      (hmrNew) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        renderMap = hmrNew.renderMap;
      },
    ),
  );
  import.meta.hot.accept(
    './state/sessionStore',
    createHot('render', './state/sessionStore', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      onChatbotPrivacyAccepted = hmrNew.onChatbotPrivacyAccepted;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      onNfkPrivacyAccepted = hmrNew.onNfkPrivacyAccepted;
    }),
  );
  import.meta.hot.accept(
    './reducer/nfkEvents',
    createHot('render', './reducer/nfkEvents', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      NFK_PRIVACY_ACCEPTED = hmrNew.NFK_PRIVACY_ACCEPTED;
    }),
  );
  import.meta.hot.accept(
    './view/blocks/nfk/renderFeedbackButton',
    createHot('render', './view/blocks/nfk/renderFeedbackButton', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ID_FEEDBACK_BUTTON = hmrNew.ID_FEEDBACK_BUTTON;
    }),
  );
  import.meta.hot.accept(
    './reducer/chatbotEvents',
    createHot('render', './reducer/chatbotEvents', (hmrNew) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      CHATBOT_PRIVACY_ACCEPTED = hmrNew.CHATBOT_PRIVACY_ACCEPTED;
    }),
  );
}
