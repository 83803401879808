import { TypeFilter } from '../../constants';
import type { HtmlString } from '../htmlTypes';
import type { PvLagenByCode } from '../../state/createInitialState';
import { Lang } from '../../util/i18n/lang';

export type SearchMoreRequest = {
  suche: string;
  regschl: string | undefined;
  pv_lage: string | undefined;
  typ: TypeFilter[];
  lang: Lang;
  // eslint-disable-next-line camelcase
  leist_score: number | undefined;
  // eslint-disable-next-line camelcase
  leist_doc: number | undefined;
  // eslint-disable-next-line camelcase
  leist_shard: number | undefined;
  // eslint-disable-next-line camelcase
  org_score: number | undefined;
  // eslint-disable-next-line camelcase
  org_doc: number | undefined;
  // eslint-disable-next-line camelcase
  org_shard: number | undefined;
  // eslint-disable-next-line camelcase
  editorial_score: number | undefined;
  // eslint-disable-next-line camelcase
  editorial_doc: number | undefined;
  // eslint-disable-next-line camelcase
  editorial_shard: number | undefined;
};
export type SearchSuggestionRequest = {
  lang: Lang;
  suche: string;
  wort?: string;
  typ: TypeFilter[];
  regschl?: string;
};
export type CitySearchRequest = {
  ort: string;
  // eslint-disable-next-line camelcase
  only_hessen: boolean;
};
export type RegschlSearchRequest = {
  regschl: string;
};

export enum SearchResultType {
  MESSAGE = 'MESSAGE',
  RESULT = 'RESULT',
}

export type SearchSuggestionFromServer = {
  value: string;
  type: SearchSuggestionType;
  key: string | undefined;
  places: Gebiet[];
};
export type SearchSuggestion = SearchSuggestionFromServer & {
  term: string;
  position: number;
};

export enum SearchSuggestionType {
  WORD = 'WORD',
  LEISTUNG = 'LEISTUNG',
  DIENSTSTELLE = 'DIENSTSTELLE',
  INFO = 'INFO',
}

export enum SearchTypeEnum {
  HESSEN = 'HESSEN',
  CITY = 'CITY',
}
export type SearchResultAskItemType = GebietResult & {
  selected: boolean;
};
export type SearchResultAskListType = SearchResultAskItemType[];
type ScoreDoc = {
  score: number;
  doc: number;
  shardIndex: number;
};
export type SearchResultEditorial = {
  location: string;
  title: string;
  description: undefined | string;
  doc: ScoreDoc;
  gueltig: boolean;
};
export type SearchResultLeistung = {
  id: string;
  leikaSchluessel: string;
  leikaLeistung: string;
  bezeichnungPlain: string;
  contentSnippet: undefined | string;
  ansprechpartner: boolean;
  onlinedienst: boolean;
  ortNotwendig: boolean;
  leichteSprache: boolean;
  doc: ScoreDoc;
  gueltig: boolean;
  validForRegschluessel: undefined | string;
};
export type SearchResultOrgeinheit = {
  id: string;
  bezeichnung: string;
  contentSnippet: undefined | string;
  doc: ScoreDoc;
  gueltig: boolean;
};
export type SearchResultData = {
  count: {
    editorialPages: undefined | number;
    leistungen: undefined | number;
    orgeinheiten: undefined | number;
  };
  hasMoreEditorial: boolean;
  hasMoreLeistungen: boolean;
  hasMoreOrgeinheiten: boolean;
  editorialRows: undefined | SearchResultEditorial[];
  leistungenRows: undefined | SearchResultLeistung[];
  orgeinheitenRows: undefined | SearchResultOrgeinheit[];
};
export type SearchType =
  | {
      type: SearchTypeEnum.CITY;
      city: GebietResult;
      internalSearchTerm: string;
    }
  | {
      type: SearchTypeEnum.HESSEN;
    };
export type SpellcheckSuggestion = {
  words: string[];
  suggestion: string;
};
export type SearchResult = {
  askList: SearchResultAskListType;
  didYouMean: SpellcheckSuggestion[];
  orgFilterCounts:
    | undefined
    | Partial<Record<RegschlLevel | RegschlFilterType, number>>;
  pvLagenCounts: Partial<Record<string, number>>;
  searchType: SearchType;
} & (
  | {
      type: SearchResultType.MESSAGE;
      message: string;
    }
  | {
      type: SearchResultType.RESULT;
      message?: string;
      result: SearchResultData;
    }
);
export type SearchResultFromServer = SearchResult & {
  breadcrumbHtml: HtmlString;
  navigationHtml?: HtmlString;
  drupalTitle?: string;
  showChatbot: boolean;
  pvLagenByCode?: PvLagenByCode;
} & (
    | {
        type: SearchResultType.MESSAGE;
        message: string;
      }
    | {
        type: SearchResultType.RESULT;
        result: SearchResultData;
        typFilterRemoved: boolean;
      }
  );

export type SearchFilterType = {
  pvLage?: string;
  type: TypeFilter[];
};

export type ServerSearchRequest = {
  // eslint-disable-next-line camelcase
  get_pv_lagen: boolean;
  lang: Lang;
  // eslint-disable-next-line camelcase
  no_cities: boolean;
  regschl: string | undefined;
  // eslint-disable-next-line camelcase
  regschl_wahl: string | undefined;
  // eslint-disable-next-line camelcase
  skip_org_filter_count: boolean;
  // eslint-disable-next-line camelcase
  skip_pv_lagen_count?: boolean;
  // eslint-disable-next-line camelcase
  skip_types_count: boolean;
  // eslint-disable-next-line camelcase
  force_type_filter: boolean;
  suche: string;
  typ: TypeFilter[];
  // eslint-disable-next-line camelcase
  pv_lage: string | undefined;
  // eslint-disable-next-line camelcase
  gebietsebene: RegschlLevel[] | undefined;
  // eslint-disable-next-line camelcase
  regschl_suchtyp: RegschlFilterType[] | undefined;
  // eslint-disable-next-line camelcase
  skip_frame: boolean;
};

export enum RegschlLevel {
  kommune = 'kommune',
  gemeindeverband = 'gemeindeverband',
  kreis = 'kreis',
  bezirk = 'bezirk',
  land = 'land',
  bund = 'bund',
}
const regschlLevelValues: string[] = Object.values(RegschlLevel);
export function isRegschlLevel(value: string): value is RegschlLevel {
  return regschlLevelValues.includes(value);
}

export enum RegschlFilterType {
  ort = 'ort',
  zustaendig = 'zustaendig',
}
const regschlFilterTypeValues: string[] = Object.values(RegschlFilterType);
export function isRegschlFilterType(value: string): value is RegschlFilterType {
  return regschlFilterTypeValues.includes(value);
}
export type BaseSearchRequestType = {
  // Filter ("Navigation", Drop-Downs und Switches)
  filter: SearchFilterType;
  regschlWahl?: string;
  searchTerm: string;
};
export type StateSearchRequestType = BaseSearchRequestType & {
  regschlFilterTypes: (RegschlLevel | RegschlFilterType)[] | undefined;
};
export type SearchRequest = BaseSearchRequestType & {
  lang: Lang;
  regschl: undefined | string;
  gebietsebene: RegschlLevel[];
  regschlSuchTyp: RegschlFilterType[];
};

export type SelectedOrt = Gebiet & {
  loadNeeded: boolean;
  isFindable: boolean;
};

export type SearchMoreResult =
  | {
      type: typeof SearchResultType.MESSAGE;
      message: string;
    }
  | { type: typeof SearchResultType.RESULT; result: SearchResultData };

export type Gebiet = {
  id: string;
  ort?: string;
  verband?: string;
  kreis?: string;
  bezirk?: string;
  land: string;
};

export type GebietResult = {
  id: string;
  ort: string | undefined;
  verband: string | undefined;
  kreis: string | undefined;
  bezirk: string | undefined;
  land: string;
  isFindable: boolean;
};
