/* eslint-disable no-param-reassign */
import type { Draft } from 'immer';
import type { State } from '../state/createInitialState';
import { RegschlFilterType, RegschlLevel } from '../action/search/searchTypes';
import { gebietToLevel } from '../util/gebietToLevel';
import { UnreachableCaseError } from '../util/UnreachableCaseError';
import { REGSCHL_BUND, REGSCHL_HESSEN } from '../constants';

export function defaultOrgSearchRegschlFilter(
  state: State,
): (RegschlLevel | RegschlFilterType)[] {
  if (state.flags.org_filter === 'dual') {
    return [RegschlFilterType.ort];
  } else if (
    !state.search.request.searchTerm &&
    state.citySearch.selectedOrt.id === REGSCHL_HESSEN
  ) {
    return [];
  } else {
    const level = gebietToLevel(state.citySearch.selectedOrt);
    return [level];
  }
}
export function resetOrgSearchRegschlFilter(state: Draft<State>) {
  state.search.request.regschlFilterTypes = undefined;
}
export function resetOrgSearchRegschlFilterIfInvalid(state: Draft<State>) {
  if (
    state.flags.org_filter === 'multi' ||
    state.flags.org_filter === 'single'
  ) {
    const { selectedOrt } = state.citySearch;
    const { regschlFilterTypes } = state.search.request;
    // den Standard-Filter und einen leeren Filter beibehalten
    if (regschlFilterTypes !== undefined && regschlFilterTypes.length) {
      const newFilter = regschlFilterTypes.filter((value) => {
        switch (value) {
          case RegschlLevel.kommune:
            return Boolean(selectedOrt.ort);
          case RegschlLevel.gemeindeverband:
            return Boolean(selectedOrt.verband);
          case RegschlLevel.kreis:
            return Boolean(selectedOrt.kreis);
          case RegschlLevel.bezirk:
            return Boolean(selectedOrt.bezirk);
          case RegschlLevel.land:
            return Boolean(selectedOrt.id !== REGSCHL_BUND);
          case RegschlLevel.bund:
            return true;
          case RegschlFilterType.ort:
          case RegschlFilterType.zustaendig:
            return false;
          default:
            throw new UnreachableCaseError(value);
        }
      });
      if (newFilter.length !== regschlFilterTypes.length) {
        if (!newFilter.length) {
          state.search.request.regschlFilterTypes =
            defaultOrgSearchRegschlFilter(state);
        } else {
          state.search.request.regschlFilterTypes = newFilter;
        }
      }
    }
  }
}
