import { patch } from 'incremental-dom';
import { Dispatch } from 'redux';
import type { ChatbotScriptSrc } from '../../constants';
import { Flags } from '../../history/flags';
import { CHATBOT_PRIVACY_ACCEPTED } from '../../reducer/chatbotEvents';
import { button } from '../../render/html';
import { setChatbotPrivacyAccepted } from '../../state/sessionStore';
import {
  BUTTON__ABBRECHEN,
  BUTTON__SCHLIESSEN,
  DATENSCHUTZ__MODAL__FEHLER__TEXT_HTML,
  COMMON__FEHLER,
  PRIVACY__MODAL__ACCEPT,
  PRIVACY__MODAL__REJECT,
  PRIVACY__REJECTED_MODAL__HEADER,
  PRIVACY__REJECTED_MODAL__TEXT_HTML,
} from '../../texts';
import {
  ID_ASK_PRIVACY_MODAL,
  renderModal,
} from '../../view/blocks/modal/renderModal';
import { loadAndOpenChatbot } from './loadAndOpenChatbot';

const ID_ASK_PRIVACY_WRAPPER = 'hzd_vwp_chatbot_ask_privacy_wrapper';

function handleAskPrivacyAcceptedButtonClicked(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
  chatbotSelectors: string[],
) {
  return () => {
    dispatch(CHATBOT_PRIVACY_ACCEPTED());
    setChatbotPrivacyAccepted();
    // Zeit lassen zum Rendern des Buttons
    setImmediate(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loadAndOpenChatbot(dispatch, flags, chatbotScriptSrc, chatbotSelectors);
    });
  };
}

function handleAskPrivacyRejectedButtonClicked(outer: HTMLDivElement) {
  patch(
    outer,
    renderModal(
      PRIVACY__REJECTED_MODAL__HEADER,
      PRIVACY__REJECTED_MODAL__TEXT_HTML,
      [
        button(
          {
            type: 'button',
            class: 'btn btn-primary shadow',
            'data-bs-dismiss': 'modal',
          },
          [BUTTON__SCHLIESSEN],
        ),
      ],
    ),
  );
  const modalElement = document.getElementById(ID_ASK_PRIVACY_MODAL);
  if (modalElement) {
    const modal = new window.bootstrap.Modal(modalElement);
    modalElement.addEventListener('hidden.bs.modal', () => {
      modal.dispose();
      document.body.removeChild(outer);
    });
    modal.show();
  }
}

export function showChatbotAskPrivacy(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
  chatbotSelectors: string[],
) {
  return () => {
    const texts = window.hzd_og_data?.datenschutz.chatbot;
    if (!document.getElementById(ID_ASK_PRIVACY_WRAPPER)) {
      const outer = document.createElement('div');
      outer.id = ID_ASK_PRIVACY_WRAPPER;
      document.body.appendChild(outer);
      let rejected = false;

      const buttons = texts
        ? [
            button(
              {
                type: 'button',
                class: 'btn btn-primary shadow',
                'data-bs-dismiss': 'modal',
                onclick: handleAskPrivacyAcceptedButtonClicked(
                  dispatch,
                  flags,
                  chatbotScriptSrc,
                  chatbotSelectors,
                ),
              },
              [PRIVACY__MODAL__ACCEPT],
            ),
            button(
              {
                type: 'button',
                class: 'btn btn-primary shadow',
                'data-bs-dismiss': 'modal',
                onclick: () => {
                  rejected = true;
                },
              },
              [PRIVACY__MODAL__REJECT],
            ),
          ]
        : [
            button(
              {
                type: 'button',
                class: 'btn btn-primary shadow',
                'data-bs-dismiss': 'modal',
                onclick: () => {
                  rejected = true;
                },
              },
              [BUTTON__ABBRECHEN],
            ),
          ];
      patch(
        outer,
        renderModal(
          texts?.title ?? COMMON__FEHLER,
          texts?.textSafeHtml ?? DATENSCHUTZ__MODAL__FEHLER__TEXT_HTML,
          buttons,
        ),
      );
      const modalElement = document.getElementById(ID_ASK_PRIVACY_MODAL);
      if (modalElement) {
        const modal = new window.bootstrap.Modal(modalElement);
        modalElement.addEventListener('hidden.bs.modal', () => {
          modal.dispose();
          if (rejected) {
            outer.replaceChildren();
            handleAskPrivacyRejectedButtonClicked(outer);
          } else {
            document.body.removeChild(outer);
          }
        });
        modal.show();
      }
    }
  };
}
