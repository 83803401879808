import { RenderResultWithSsr } from '../../../lib/renderTypes';
import { ChatbotState } from '../../../state/createInitialState';
import { renderChatbotButton } from './renderChatbotButton';

export const ID_CHATBOT_BUTTON = 'vwp_nfk_chatbot_button';

export function renderChatbotButtonUnloaded(
  chatbotState: ChatbotState,
  onclick: undefined | ((this: HTMLElement) => void),
  buttonSrc?: string,
): RenderResultWithSsr | undefined {
  return renderChatbotButton(chatbotState.loading, onclick, buttonSrc);
}
