import { TypeFilter } from './constants';
import { UnreachableCaseError } from './util/UnreachableCaseError';
import { IconId } from './view/elements/icon';

/* eslint-disable i18next/no-literal-string */

export const BUTTON__ABBRECHEN = 'Abbrechen';
export const BUTTON__EINGABE_LEEREN = 'Eingabe leeren';
export const BUTTON__SCHLIESSEN = 'Schließen';
export const BUTTON__ZURUECK = 'Zurück';
export const CHATBOT__MODAL__NICHT_VERFUEGBAR__HEADER =
  'Chatbot-Funktion aktuell nicht verfügbar';
export const CHATBOT__MODAL__NICHT_VERFUEGBAR__TEXT =
  'Leider steht die Chatbot-Funktion aktuell nicht zur Verfügung, bitte versuchen Sie es später erneut';
export const COMMON__BUND = 'Bund';
export const COMMON__FEHLER = 'Fehler';
export const COMMON__HESSEN = 'Hessen';
export const COMMON__LAND_HESSEN = 'Land Hessen';
export const DATENSCHUTZ__MODAL__FEHLER__TEXT_HTML =
  'Leider ist ein Fehler aufgetreten';
export const FEEDBACK__BUTTON_TEXT = 'Feedback senden';
export const GEBIETSANGABE__GEMEINDEVERBAND_PRAEFIX = 'Gemeindeverband';
export const GEBIETSANGABE__KREIS_PRAEFIX = 'Landkreis';
export const LISTE__TRENNER = ', ';
export const LISTE__UND = ' und ';
export function LIST(entries: string[]) {
  if (entries.length === 0) {
    return '';
  } else if (entries.length === 1) {
    return entries[0];
  } else {
    return (
      entries.slice(0, entries.length - 1).join(LISTE__TRENNER) +
      LISTE__UND +
      entries[entries.length - 1]
    );
  }
}
export const NFK__MODAL__NICHT_VERFUEGBAR__HEADER =
  'Feedback-Funktion aktuell nicht verfügbar';
export const NFK__MODAL__NICHT_VERFUEGBAR__TEXT =
  'Leider steht die Feedback-Funktion aktuell nicht zur Verfügung, bitte versuchen Sie es später erneut';
export const ODMAP__BUTTON__ZOOM_IN__TITEL = 'Hereinzoomen';
export const ODMAP__BUTTON__ZOOM_OUT__TITEL = 'Herauszoomen';
export const ODMAP__BUTTON__ZOOM_RESET__TITEL = 'Ganz Hessen';
export const ODMAP__HINWEIS =
  'Wählen Sie eine Gemeinde, eine Stadt oder einen Landkreis aus der Karte aus oder verwenden Sie die Suche. Über die Breadcrumb gelangen Sie zur vorherigen Ebene zurück.';
export const ODMAP__HINWEIS__ONLINEDIENSTE_WERDEN_BERECHNET =
  'Die Anzahl der Online-Dienste wird momentan berechnet. Sobald die Berechnung abgeschlossen ist, sehen Sie hier die Anzahl der verfügbaren Online-Dienste.';
export const ODMAP__OD_WERDEN_BEREITGESTELLT_VON =
  'Diese Online-Dienste werden bereitgestellt von:';
export function ODMAP__X_ONLINEDIENSTE_VERFUEGBAR(
  count: number,
  showVerfuegbar: boolean,
) {
  const suffix = showVerfuegbar ? ' verfügbar' : '';
  if (count === 1) {
    return `1 Online-Dienst${suffix}`;
  } else {
    return `${count} Online-Dienste${suffix}`;
  }
}
export const ODMAP__ORT_INPUT__BESCHREIBUNG = 'Geben Sie einen Ort ein';
export const ODMAP__ORT_INPUT__TITEL = 'Gemeinde\u00A0/ Stadt\u00A0/ Landkreis';
export const ODMAP__TITEL = 'Online-Dienste in Hessen';
export const ORGSUCHE__ERGEBNIS__ERKLAERUNG =
  'Behörden – zum Nachfiltern oder Erweitern der Suchergebnisse können die Filter weiter oben verwendet werden';
export const ORGSUCHE__FILTER__BUTTON_DEUTSCHLAND = 'Deutschland';
export const ORGSUCHE__FILTER__BUTTON_IN_ORT = (ort: string) =>
  `Behörden in ${ort}`;
export const ORGSUCHE__FILTER__BUTTON_KREIS = 'Kreis';
export const ORGSUCHE__FILTER__BUTTON_ORT = 'Ort';
export const ORGSUCHE__FILTER__BUTTON_REGIERUNGSBEZIRK = 'Regierungsbezirk';
export const ORGSUCHE__FILTER__BUTTON_ZUSTAENDIG_FUER_ORT = (ort: string) =>
  `Behörden zuständig für ${ort}`;
export const ORGSUCHE__FILTER__ERKLAERUNG =
  'Zuständigkeiten können auf den verschiedenen' +
  ' Verwaltungsebenen liegen. Mit dem Filter können Sie Ihre Suche auf die' +
  ' verschiedenen Verwaltungsebenen erweitern oder einschränken.';
export const ORGSUCHE__FILTER__TITEL = 'Filter';
export const ORGSUCHE__INPUT__BESCHREIBUNG =
  'Geben Sie eine Behörde oder ein Stichwort ein ';
export const ORGSUCHE__INPUT__TITEL = 'Behörde\u00A0/ Stichwort';
export const ORGSUCHE__TEXT =
  'Die Behördensuche hilft Ihnen Behörden zu finden, die für eine Gemeinde und für Themen zuständig sind.';
export const ORGSUCHE__TITEL = 'Behördensuche';
export const ORT__FEEDBACK__BITTE_AUSWAEHLEN =
  'Bitte wählen Sie einen der vorgeschlagenen Orte aus:';
export const ORT__FEEDBACK__EINGABE_FEHLT =
  'Bitte geben Sie einen Ortsnamen ein.';
export const ORT__FEEDBACK__EINGABE_UNGUELTIG =
  'Bitte geben Sie einen gültigen Suchbegriff ein';
export const ORT__FEEDBACK__LINK_NICHT_GEFUNDEN =
  'Der gesuchte Ort konnte nicht gefunden werden.';
export const ORT__FEEDBACK__NICHT_GEFUNDEN =
  'Der eingegebene Ort ergab keine Treffer';
export const ORT__FEEDBACK__WIRD_GELADEN =
  'Der eingegebene Ort wird gesucht...';
export const ORT__INPUT__BESCHREIBUNG =
  'Geben Sie einen Ort oder eine Postleitzahl ein';
export const ORT__INPUT__TITEL = 'Stadt\u00A0/ Ort\u00A0/ PLZ';
export const PRIVACY__MODAL__ACCEPT = 'Ja, ich stimme zu';
export const PRIVACY__MODAL__REJECT = 'Nein, ich stimme nicht zu';
export const PRIVACY__REJECTED_MODAL__HEADER = 'Funktion nicht möglich';
export const PRIVACY__REJECTED_MODAL__TEXT_HTML = `
<p>Sie sind mit den Datenschutzhinweisen nicht einverstanden.</p>
<p>Leider kann die Funktion dann nicht verwendet werden.</p>
`;
export const SEITENTITEL__FALLBACK = 'Verwaltungsportal Hessen';
export const STARTSEITE__BEREICHWAHL__BUERGER = 'Bürgerinnen | Bürger';
export const STARTSEITE__BEREICHWAHL__TITEL = 'Suche im Thema:';
export const STARTSEITE__BEREICHWAHL__UNTERNEHMEN = 'Unternehmen';
export const SUCHE__BEREICH__ARIA = 'Suche';
export const SUCHE__BUTTON__ARIA = 'Suchen';
export const SUCHE__FEEDBACK__EINGABE_FEHLT =
  'Bitte geben Sie Suchbegriffe ein.';
export const SUCHE__FEEDBACK__FEHLER =
  'Es ist leider ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben oder versuchen Sie es später erneut.';
export const SUCHE__FEEDBACK__MEINTEN_SIE =
  'Möchten Sie nach folgendem suchen?';
export const SUCHE__FEEDBACK__WO_SUCHEN =
  'Wo möchten Sie suchen? Bitte wählen Sie:';
export const SUCHE__FILTERBEREICH__TITEL = 'Suchfilter';
export const SUCHE__INPUT__LABEL =
  'Verwaltungsleistung\u00A0/ Behörde\u00A0/ Stichwort';
export const SUCHE__INPUT__PLATZHALTER_BUERGER =
  'Suche im Bereich Bürgerinnen | Bürger';
export const SUCHE__INPUT__PLATZHALTER_UNTERNEHMEN =
  'Suche im Bereich Unternehmen';
export const SUCHE__NICHT_GEFUNDEN =
  'Keine Ergebnisse gefunden. Bitte überprüfen Sie Ihre Eingaben.';
export const SUCHE__TYPFILTER__TITEL = 'Filter';
export const SUCHERGEBNIS__ALLE_BEHOERDEN_ANZEIGEN = (count: number) =>
  `Alle ${count} Behörden anzeigen`;
export const SUCHERGEBNIS__ALLE_INFORMATIONEN_ANZEIGEN = (count: number) =>
  `Alle ${count} Infoseiten anzeigen`;
export const SUCHERGEBNIS__ALLE_LEISTUNGEN_ANZEIGEN = (count: number) =>
  `Alle ${count} Leistungen anzeigen`;
export const SUCHERGEBNIS__ANSPRECHPARTNER_VORHANDEN =
  'Ansprechpartner verfügbar';
export const SUCHERGEBNIS__ANZAHL_BEHOERDEN = (count: number) =>
  `${count} ${singleMulti(count, 'Behörde', 'Behörden')}`;
export const SUCHERGEBNIS__ANZAHL_INFORMATIONEN = (count: number) =>
  `${count} ${singleMulti(count, 'Infoseite', 'Infoseiten')}`;
export const SUCHERGEBNIS__ANZAHL_LEISTUNGEN = (count: number) =>
  `${count} ${singleMulti(count, 'Leistung', 'Leistungen')}`;
export const SUCHERGEBNIS__AUSWERTUNGSFEHLER =
  'Fehler beim Auswerten der Suchergebnisse';
export const SUCHERGEBNIS__FUER_SUCHBEGRIFF = (suchbegriff: string) =>
  `für „${suchbegriff}“`;
export const SUCHERGEBNIS__IN_REGION = (region: string) => `in ${region}`;
export const SUCHERGEBNIS__LEICHTE_SPRACHE_VERFUEGBAR =
  'Leichte Sprache verfügbar';
export const SUCHERGEBNIS__NICHT_GEFUNDEN = 'Keine Ergebnisse';
export const SUCHERGEBNIS__ONLINE_DIENST_VERFUEGBAR = 'Online-Dienst verfügbar';
export const SUCHERGEBNIS__ORTSANGABE_NOETIG = 'Ortsangabe notwendig';
export const SUCHERGEBNIS__SUCHFEHLER =
  'Es ist leider ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben oder versuchen Sie es später erneut.';
export const SUCHERGEBNIS__TEXT__EIN_TYP_EIN_ERGEBNIS = (
  forSearch: string | undefined,
  inRegion: string | undefined,
  resultCount: string,
) =>
  `Es wurde ${forSearch ? `${forSearch} ` : ''}${inRegion ? `${inRegion} ` : ''}${resultCount} gefunden.`;
export const SUCHERGEBNIS__TEXT__MEHRERE_ERGEBNISSE = (
  forSearch: string | undefined,
  inRegion: string | undefined,
  resultCount: string,
) =>
  `Es wurden ${forSearch ? `${forSearch} ` : ''}${inRegion ? `${inRegion} ` : ''}${resultCount} gefunden.`;
export const SUCHERGEBNIS__TEXT__NICHT_GEFUNDEN = (
  forSearch: string | undefined,
  inRegion: string | undefined,
) =>
  `Es wurden ${forSearch ? `${forSearch} ` : ''}${inRegion ? `${inRegion} ` : ''}keine Ergebnisse gefunden.`;
export const SUCHERGEBNIS__TITEL = 'Suchergebnisse';
export const SUCHERGEBNIS__TITEL_BEHOERDEN = 'Behörden';
export const SUCHERGEBNIS__TITEL_INFORMATIONEN = 'Infoseiten';
export const SUCHERGEBNIS__TITEL_LEISTUNGEN = 'Leistungen';
export const SUCHERGEBNIS__TYPFILTER_ENTFERNT = (types: TypeFilter[]): string =>
  `Für den Filter "${types
    .map(SUCHE__TYPFILTER__BUTTON_TEXT)
    .join(
      ', ',
    )}" wurden keine Suchergebnisse gefunden, daher wurde ohne Filter gesucht.`;
export const TEILEN__GENERIC__ACTION__TEXT = 'Teilen';
// eslint-disable-next-line no-template-curly-in-string
export const TEILEN__PLATZHALTER__TITEL = '${title}';
// eslint-disable-next-line no-template-curly-in-string
export const TEILEN__PLATZHALTER__URL = '${url}';
export type ShareTemplateType =
  | { action: 'link'; linkUrl: string }
  | { action: 'print' | 'share' };
export type ShareTemplate = ShareTemplateType & {
  alt: string;
  iconId: IconId;
  statisticTitle: string;
};

function singleMulti(count: number, single: string, multi: string) {
  return count === 1 ? single : multi;
}

export function SUCHE__TYPFILTER__BUTTON_TEXT(type: TypeFilter): string {
  switch (type) {
    case TypeFilter.info:
      return 'Infoseiten';
    case TypeFilter.leistung:
      return 'Leistungen';
    case TypeFilter.orgeinheit:
      return 'Behörden';
    default:
      throw new UnreachableCaseError(type);
  }
}
