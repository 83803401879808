/* eslint-disable no-param-reassign */
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { Draft, produce } from 'immer';
import {
  REGSCHL_HESSEN,
  SELECTED_ORT_HESSEN,
  TypeFilter,
} from '../../constants';
import type { State } from '../../state/createInitialState';
import {
  removeAbortRequest,
  storeAbortRequest,
} from '../../state/createInitialState';
import { SUCHERGEBNIS__TYPFILTER_ENTFERNT } from '../../texts';
import { deepEquals } from '../../util/deepEquals';
import {
  getBereichCode,
  getPvLagenLevel,
  PvLagenLevel,
} from '../../util/pvLagenUtil';
import { requestWithoutTypes } from '../../util/requestWithoutTypes';
import { UnreachableCaseError } from '../../util/UnreachableCaseError';
import { buildSuggestions } from '../../view/read/extractDropDownOptions';
import { combinePlaceNamesForInput } from '../../view/util/combinePlaceNames';
import { trimAndShorten } from '../util/trimAndShorten';
import { Dropdowns } from './searchCommon';
import {
  CITY_FEEDBACK_HIDDEN,
  CITY_FEEDBACK_SHOWN,
  CITY_SEARCH_ABORTED,
  CITY_SEARCH_ERROR,
  CITY_SEARCH_FINISHED,
  CITY_SEARCH_NOT_FOUND,
  CITY_SEARCH_RESET,
  CITY_SEARCH_RESETTED_TO_SELECTED,
  CITY_SEARCH_STARTED,
  CITY_SEARCH_TERM_TYPED,
  CITY_SELECTED,
  CITY_SELECTED_FROM_ASK,
  REGSCHL_SEARCH_ABORTED,
  REGSCHL_SEARCH_ERROR,
  REGSCHL_SEARCH_FINISHED,
  REGSCHL_SEARCH_STARTED,
  SEARCH_CLEARED,
  SEARCH_ERROR,
  SEARCH_FINISHED,
  SEARCH_MORE_ERROR,
  SEARCH_MORE_FINISHED,
  SEARCH_MORE_NOT_FOUND,
  SEARCH_MORE_STARTED,
  SEARCH_RESULTS_HIDDEN,
  SEARCH_STARTED,
  SEARCH_SUGGESTION_ABORTED,
  SEARCH_SUGGESTION_ERROR,
  SEARCH_SUGGESTION_FINISHED,
  SEARCH_SUGGESTION_NOT_FOUND,
  SEARCH_SUGGESTION_STARTED,
  SEARCH_TERM_CHANGED,
  SEARCH_TERM_TRIMMED,
  SEARCH_TERM_TYPED,
  SEARCHES_ABORTED,
} from './searchEvents';
import type { SearchResult } from './searchTypes';
import { SearchResultType, SearchTypeEnum } from './searchTypes';
import { getPatch } from '../../lib/htmlCache';
import { Screen } from '../../view';
import type { HtmlPatchOrgSuche, HtmlPatchSuche } from '../htmlTypes';
import type {
  ScreenRequestOrgSuche,
  ScreenRequestSuche,
} from '../common/commonRequestTypes';
import { requestWithoutOrgFilter } from '../../util/requestWithoutOrgFilter';
import {
  resetOrgSearchRegschlFilter,
  resetOrgSearchRegschlFilterIfInvalid,
} from '../../reducer/resetOrgSearchRegschlFilter';
import { reduceForNewPatch } from '../../reducer/reduceForNewPatch';
import { requestWithoutPvLage } from '../../util/requestWithoutPvLage';
import { reduceForScreenChange } from '../../reducer/reduceForScreenChange';

function calcScreen(state: Draft<State>) {
  // nur Screen wechseln, wenn wir aktuell nicht auf der Startseite und nicht in der Org-Suchesind
  if (state.screen !== Screen.Startseite && state.screen !== Screen.OrgSuche) {
    let newScreen: Screen;
    if (state.search.request.searchTerm) {
      newScreen = Screen.Suche;
    } else {
      const { pvLage } = state.search.request.filter;
      if (pvLage) {
        const level = getPvLagenLevel(pvLage);
        switch (level) {
          case PvLagenLevel.BEREICH:
            newScreen = Screen.Bereich;
            break;
          case PvLagenLevel.LAGE:
            newScreen = Screen.Lage;
            break;
          case PvLagenLevel.SUBLAGE:
            newScreen = Screen.Sublage;
            break;
          default:
            throw new UnreachableCaseError(level);
        }
      } else {
        newScreen = Screen.Bereich;
      }
    }
    if (state.screen !== newScreen) {
      state.screen = newScreen;
      reduceForScreenChange(state);
    }
  }
}

export function createSearchReducer(builder: ActionReducerMapBuilder<State>) {
  builder.addCase(CITY_SEARCH_ABORTED, (state) => {
    removeAbortRequest(state, 'citySearch');
  });
  builder.addCase(CITY_SEARCH_ERROR, (state, action) => {
    removeAbortRequest(state, 'citySearch');
    state.error.citySearch = action.payload;
  });
  builder.addCase(CITY_SEARCH_FINISHED, (state, action) => {
    const { data, forRequest } = action.payload;
    removeAbortRequest(state, 'citySearch');
    state.citySearch.suggestions = { data, notFound: false, forRequest };
  });
  builder.addCase(CITY_SEARCH_NOT_FOUND, (state, action) => {
    removeAbortRequest(state, 'citySearch');
    state.citySearch.suggestions = {
      notFound: true,
      data: [],
      forRequest: action.payload,
    };
  });
  builder.addCase(CITY_SEARCH_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'citySearch', abortRequest);
    state.citySearch.showValidationErrors = false;
    state.error.citySearch = undefined;
  });
  builder.addCase(CITY_SEARCH_RESET, (state) => {
    state.citySearch.suggestions = {
      data: [],
      notFound: false,
      forRequest: { ort: '', only_hessen: false },
    };
    state.citySearch.input.searchTerm = '';
    state.citySearch.selectedOrt = SELECTED_ORT_HESSEN;
    resetOrgSearchRegschlFilter(state);
  });
  builder.addCase(CITY_SEARCH_RESETTED_TO_SELECTED, (state) => {
    const { citySearch } = state;
    const selected = citySearch.selectedOrt;
    citySearch.input.searchTerm = selected.isFindable
      ? combinePlaceNamesForInput(selected)
      : '';
  });
  builder.addCase(CITY_SEARCH_TERM_TYPED, (state, action) => {
    state.citySearch.input.searchTerm = action.payload;
    state.error.regschlSearch = undefined;
  });
  builder.addCase(CITY_SELECTED, (state, action) => {
    const selectedOrt = action.payload || SELECTED_ORT_HESSEN;
    const { citySearch } = state;
    citySearch.input.searchTerm = combinePlaceNamesForInput(selectedOrt);
    state.citySearch.selectedOrt = selectedOrt;
    state.search.suggestionData = [];
    state.dropdowns[Dropdowns.CITY].selection = undefined;
    resetOrgSearchRegschlFilter(state);
  });
  builder.addCase(CITY_SELECTED_FROM_ASK, (state, action) => {
    const { searchTerm, selectedOrt } = action.payload;
    const { citySearch } = state;
    citySearch.input.searchTerm = combinePlaceNamesForInput(selectedOrt);
    citySearch.selectedOrt = { loadNeeded: false, ...selectedOrt };
    state.search.request.searchTerm = searchTerm;
    resetOrgSearchRegschlFilter(state);
  });
  builder.addCase(CITY_FEEDBACK_HIDDEN, (state) => {
    state.citySearch.feedbackHint = false;
  });
  builder.addCase(CITY_FEEDBACK_SHOWN, (state) => {
    state.citySearch.feedbackHint = true;
  });
  builder.addCase(REGSCHL_SEARCH_ABORTED, (state) => {
    removeAbortRequest(state, 'regschlSearch');
  });
  builder.addCase(REGSCHL_SEARCH_ERROR, (state, action) => {
    removeAbortRequest(state, 'regschlSearch');
    state.error.regschlSearch = action.payload;
    state.citySearch.selectedOrt = SELECTED_ORT_HESSEN;
    resetOrgSearchRegschlFilter(state);
  });
  builder.addCase(REGSCHL_SEARCH_FINISHED, (state, action) => {
    const { data, forRequest } = action.payload;
    const { citySearch } = state;
    removeAbortRequest(state, 'regschlSearch');
    if (citySearch.selectedOrt.id === forRequest.regschl) {
      if (data.isFindable) {
        const ort = combinePlaceNamesForInput(data);
        citySearch.suggestions = {
          data: [data],
          forRequest: {
            ort,
            only_hessen: false,
          },
          notFound: false,
        };
        citySearch.input.searchTerm = ort;
      } else {
        citySearch.suggestions = {
          data: [],
          forRequest: { ort: '', only_hessen: false },
          notFound: true,
        };
        citySearch.input.searchTerm = '';
      }
      citySearch.selectedOrt = { loadNeeded: false, ...data };
      resetOrgSearchRegschlFilterIfInvalid(state);
    }
  });
  builder.addCase(REGSCHL_SEARCH_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'regschlSearch', abortRequest);
    state.citySearch.suggestions.notFound = false;
    state.citySearch.showValidationErrors = false;
    state.error.regschlSearch = undefined;
  });
  builder.addCase(SEARCH_CLEARED, (state) => {
    state.search.results = {
      data: undefined,
      notFound: false,
      forRequest: undefined,
      previewOnly: {
        editorialPages: true,
        leistungen: true,
        orgeinheiten: true,
      },
    };
    state.search.request.searchTerm = '';
    state.search.request.regschlWahl = undefined;
    state.search.suggestionData = [];
    state.dropdowns[Dropdowns.SEARCH].selection = undefined;
    state.error.search = undefined;
    calcScreen(state);
  });
  builder.addCase(SEARCH_ERROR, (state, action) => {
    const error = action.payload;
    removeAbortRequest(state, 'search');
    state.error.search = error;
  });
  builder.addCase(SEARCH_FINISHED, (state, action) => {
    const {
      forRequest,
      resultHasFrame,
      results: resultFromServer,
    } = action.payload;
    // Überprüfen, ob das Ergebnis vom Server mit der letzten Anfrage übereinstimmt
    if (!deepEquals(state.abortRequest.search?.forRequest, forRequest)) {
      // Der Nutzer hatte zwischenzeitlich eine neue Suche gestartet
      // daher müssen diese Suchergebnisse, die für eine frühere Anfrage sind, ignoriert werden
      return;
    }

    removeAbortRequest(state, 'search');
    let resultsWithMergedCount: SearchResult;
    const oldResults = state.search.results;
    let typFilterRemoved: boolean;
    let message: string | undefined;
    if (
      // wenn es neue Ergebnisse gibt
      resultFromServer.type === SearchResultType.RESULT
    ) {
      // prüfen, ob der Typ-Filter entfernt wurde, da sonst keine Ergebnisse gefunden wurden
      const { typFilterRemoved: typFilterRemoved2, ...resultsForClient } =
        resultFromServer;
      typFilterRemoved = typFilterRemoved2;
      if (typFilterRemoved) {
        message = SUCHERGEBNIS__TYPFILTER_ENTFERNT(forRequest.filter.type);
        state.search.request.filter.type = [];
      } else {
        message = undefined;
      }

      if (
        // falls es alte Typ-Filter-Zahlen gibt
        oldResults.data &&
        oldResults.data.type === SearchResultType.RESULT &&
        // und die Suchparameter außer des Typs gleich sind
        deepEquals(
          requestWithoutTypes(forRequest),
          requestWithoutTypes(oldResults.forRequest),
        )
      ) {
        // dann übernehme die alten Zahlen, falls in der neuen Antwort Zahlen fehlen
        const oldCount = oldResults.data.result.count;
        const newCount = resultsForClient.result.count;
        const mergedCount = {
          editorialPages: newCount.editorialPages ?? oldCount.editorialPages,
          leistungen: newCount.leistungen ?? oldCount.leistungen,
          orgeinheiten: newCount.orgeinheiten ?? oldCount.orgeinheiten,
        };
        resultsWithMergedCount = produce(resultsForClient, (draft) => {
          draft.result.count = mergedCount;
        });
      } else {
        resultsWithMergedCount = resultsForClient;
      }
    } else {
      // es wurde ein Text anstelle von Suchergebnissen zurückgegeben
      resultsWithMergedCount = resultFromServer;
      typFilterRemoved = false;
      message = resultFromServer.message;
    }
    if (
      // falls es alte Org-Filter-Zahlen gibt
      oldResults.data &&
      oldResults.data.orgFilterCounts &&
      // und die Suchparameter außer des Org-Filters gleich sind
      deepEquals(
        requestWithoutOrgFilter(forRequest),
        requestWithoutOrgFilter(oldResults.forRequest),
      )
    ) {
      const oldOrgFilterCounts = oldResults.data.orgFilterCounts;
      // dann übernehme die alten Zahlen, falls in der neuen Antwort Zahlen fehlen
      resultsWithMergedCount = produce(resultsWithMergedCount, (draft) => {
        draft.orgFilterCounts = oldOrgFilterCounts;
      });
    }
    if (
      // falls es alte PV-Lagen-Zahlen gibt
      oldResults.data &&
      // und die Suchparameter außer der PV-Lage gleich sind
      deepEquals(
        requestWithoutPvLage(forRequest),
        requestWithoutPvLage(oldResults.forRequest),
      )
    ) {
      const oldPvLagenCounts = oldResults.data.pvLagenCounts;
      // dann übernehme die alten Zahlen, falls in der neuen Antwort Zahlen fehlen
      resultsWithMergedCount = produce(resultsWithMergedCount, (draft) => {
        draft.pvLagenCounts = oldPvLagenCounts;
      });
    }
    const data =
      resultsWithMergedCount.type === SearchResultType.MESSAGE
        ? {
            ...resultsWithMergedCount,
            message: message ?? '',
          }
        : {
            ...resultsWithMergedCount,
            message: '',
          };
    state.search.results = {
      data,
      forRequest: {
        ...forRequest,
        filter: {
          ...forRequest.filter,
          type: typFilterRemoved ? [] : [...forRequest.filter.type].sort(),
        },
      },
      notFound: false,
      previewOnly: {
        editorialPages: true,
        leistungen: true,
        orgeinheiten: true,
      },
    };
    const screen =
      state.screen === Screen.OrgSuche ? Screen.OrgSuche : Screen.Suche;
    const patchRequest: ScreenRequestOrgSuche | ScreenRequestSuche = { screen };
    const existingFrame = getPatch(state, patchRequest);

    let frame: HtmlPatchOrgSuche | HtmlPatchSuche | undefined;
    if (resultHasFrame) {
      frame = {
        data: {
          screen:
            // Workaround für falsche TypeScript-Erkennung
            screen as Screen.Suche,
        },
        pageElements: {
          breadcrumbHtml: resultFromServer.breadcrumbHtml,
          drupalTitle: resultFromServer.drupalTitle,
          htmlHead: {},
          navigationHtml: resultFromServer.navigationHtml,
          odMapDomIds: [],
          showChatbot: resultFromServer.showChatbot,
        },
      };
    } else if (existingFrame) {
      frame = {
        data: {
          screen:
            // Workaround für falsche TypeScript-Erkennung
            screen as Screen.Suche,
        },
        pageElements: {
          breadcrumbHtml: resultFromServer.breadcrumbHtml,
          drupalTitle: existingFrame.pageElements.drupalTitle,
          htmlHead: existingFrame.pageElements.htmlHead,
          navigationHtml: resultFromServer.navigationHtml,
          odMapDomIds: [],
          showChatbot: resultFromServer.showChatbot,
        },
      };
    } else {
      frame = undefined;
    }
    if (frame) {
      reduceForNewPatch(state, {
        patch: frame,
        screenRequest: { screen: frame.data.screen },
      });
    }
    removeAbortRequest(state, 'citySearch');
    if (resultFromServer.type === SearchResultType.RESULT) {
      if (resultFromServer.searchType.type === SearchTypeEnum.CITY) {
        if (
          // falls ein (eventuell geänderter) Suchbegriff vom Server zurückgegeben wurde
          resultFromServer.searchType.internalSearchTerm &&
          // und der Nutzer den eingegebenen Suchbegriff seit Start der Suche nicht geändert hat
          state.search.request.searchTerm === forRequest.searchTerm
        ) {
          // den Suchbegriff vom Server als aktuellen Input-Wert übernehmen
          state.search.request.searchTerm =
            resultFromServer.searchType.internalSearchTerm;
        }
        if (!resultFromServer.askList.length) {
          state.search.request.regschlWahl = undefined;
        }
        if (resultFromServer.searchType.city.id === REGSCHL_HESSEN) {
          state.citySearch.suggestions = {
            data: [],
            forRequest: { ort: '', only_hessen: false },
            notFound: false,
          };
          state.citySearch.input.searchTerm = '';
          state.citySearch.selectedOrt = SELECTED_ORT_HESSEN;
        } else {
          const { city } = resultFromServer.searchType;
          if (city.isFindable) {
            const ort = combinePlaceNamesForInput(city);
            state.citySearch.input.searchTerm = ort;
            state.citySearch.suggestions = {
              data: [city],
              forRequest: { ort, only_hessen: false },
              notFound: false,
            };
          } else {
            state.citySearch.input.searchTerm = '';
            state.citySearch.suggestions = {
              data: [],
              forRequest: {
                ort: '',
                only_hessen: false,
              },
              notFound: false,
            };
          }
          state.citySearch.selectedOrt = {
            loadNeeded: false,
            ...city,
          };
        }
      }
      // falls nach einer PV-Lage gefiltert wird, welches nicht in der PV-Lagenliste enthalten ist, lösche den
      // PV-Lagenfilter. Dies kann z.B. passieren, wenn nach "Sonstiges" gefiltert wird, aber nach Ändern der Suche
      // es keine sonstigen Ergebnisse mehr gibt
      /* if (forRequest.filter.pvLage === 'PV_LAGE_CODE_OTHER') {
        const countOther = results.result.count.pvLagen?.bereiche.some(
          (bereich) =>
            bereich.lagen.some(
              (lage) => lage.code === 'PV_LAGE_CODE_OTHER' && lage.count,
            ),
        );
        if (!countOther) {
          state.search.request.filter.pvLage = undefined;
        }
      } */
    }
  });
  builder.addCase(SEARCH_MORE_ERROR, (state, action) => {
    removeAbortRequest(state, 'searchMore');
    state.error.search = action.payload;
  });
  builder.addCase(SEARCH_MORE_FINISHED, (state, action) => {
    removeAbortRequest(state, 'searchMore');
    if (!state.search.results.data) {
      throw new Error('state.search.results.data is undefined');
    }
    const { searchType, results } = action.payload;
    if (results.type === SearchResultType.MESSAGE) {
      state.search.results.data = {
        ...state.search.results.data,
        type: results.type,
        message: results.message,
      };
    } else {
      const dataType = state.search.results.data.type;
      if (dataType === SearchResultType.MESSAGE) {
        throw new Error('state.search.data.searchResults is of left type');
      } else {
        // state.search.results.data.type === SearchResultType.RESULT
        const data = state.search.results.data.result;
        const { previewOnly } = state.search.results;
        const { result } = results;
        switch (searchType) {
          case TypeFilter.leistung:
            data.hasMoreLeistungen = result.hasMoreLeistungen;
            if (result.leistungenRows) {
              data.leistungenRows = (data.leistungenRows ?? []).concat(
                result.leistungenRows,
              );
            }
            previewOnly.leistungen = false;
            break;
          case TypeFilter.info:
            data.hasMoreEditorial = result.hasMoreEditorial;
            if (result.editorialRows) {
              data.editorialRows = (data.editorialRows ?? []).concat(
                result.editorialRows,
              );
            }
            previewOnly.editorialPages = false;
            break;
          case TypeFilter.orgeinheit:
            data.hasMoreOrgeinheiten = result.hasMoreOrgeinheiten;
            if (result.orgeinheitenRows) {
              data.orgeinheitenRows = (data.orgeinheitenRows ?? []).concat(
                result.orgeinheitenRows,
              );
            }
            previewOnly.orgeinheiten = false;
            break;
          default:
            throw new UnreachableCaseError(searchType);
        }
      }
    }
  });
  builder.addCase(SEARCH_MORE_NOT_FOUND, (state, action) => {
    const searchType = action.payload;
    removeAbortRequest(state, 'searchMore');
    const searchData = state.search.results.data;
    if (!searchData) {
      throw new Error('Invalid state: state.search.results.data is undefined');
    }
    if (searchData.type === SearchResultType.MESSAGE) {
      throw new Error(
        `Invalid state: state.search.results.data.searchResults.type is '${SearchResultType.MESSAGE}' (expected: '${SearchResultType.RESULT}')`,
      );
    }
    const { previewOnly } = state.search.results;
    switch (searchType) {
      case TypeFilter.leistung:
        searchData.result.hasMoreLeistungen = false;
        previewOnly.leistungen = false;
        break;
      case TypeFilter.info:
        searchData.result.hasMoreEditorial = false;
        previewOnly.editorialPages = false;
        break;
      case TypeFilter.orgeinheit:
        searchData.result.hasMoreOrgeinheiten = false;
        previewOnly.orgeinheiten = false;
        break;
      default:
        throw new UnreachableCaseError(searchType);
    }
  });
  builder.addCase(SEARCH_MORE_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'searchMore', abortRequest);
  });
  builder.addCase(SEARCH_RESULTS_HIDDEN, (state) => {
    // Bei Ändern der Eingabedaten soll das Suchergebnis ausgeblendet werden (falls es aktuell angezeigt wird)
    state.search.results.data = undefined;
    state.search.results.notFound = false;
    state.error.search = undefined;
  });
  builder.addCase(SEARCH_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'search', abortRequest);
    state.error.search = undefined;
    const searchState = state.search;
    searchState.results.notFound = false;
    searchState.showValidationErrors = false;
  });
  builder.addCase(SEARCH_SUGGESTION_ABORTED, (state) => {
    removeAbortRequest(state, 'searchSuggestion');
  });
  builder.addCase(SEARCH_SUGGESTION_ERROR, (state, action) => {
    removeAbortRequest(state, 'searchSuggestion');
    state.error.searchSuggestion = action.payload;
  });
  builder.addCase(SEARCH_SUGGESTION_FINISHED, (state, action) => {
    const { data, position, term } = action.payload;
    removeAbortRequest(state, 'searchSuggestion');
    state.search.suggestionData = buildSuggestions(data, term, position);
  });
  builder.addCase(SEARCH_SUGGESTION_NOT_FOUND, (state) => {
    removeAbortRequest(state, 'searchSuggestion');
    state.search.suggestionData = [];
  });
  builder.addCase(SEARCH_SUGGESTION_STARTED, (state, action) => {
    const abortRequest = action.payload;
    storeAbortRequest(state, 'searchSuggestion', abortRequest);
    state.error.searchSuggestion = undefined;
  });
  builder.addCase(SEARCH_TERM_CHANGED, (state, action) => {
    state.search.request.searchTerm = action.payload;
    state.search.request.regschlWahl = undefined;
    state.search.request.filter.pvLage = getBereichCode(
      state.search.request.filter.pvLage,
    );
    calcScreen(state);
  });
  builder.addCase(SEARCH_TERM_TRIMMED, (state) => {
    state.search.request.searchTerm = trimAndShorten(
      state.search.request.searchTerm,
    );
    calcScreen(state);
  });
  builder.addCase(SEARCH_TERM_TYPED, (state, action) => {
    state.search.request.searchTerm = action.payload;
    state.search.request.regschlWahl = undefined;
    state.search.request.filter.type = [];
    calcScreen(state);
  });
  builder.addCase(SEARCHES_ABORTED, (state) => {
    removeAbortRequest(state, 'citySearch');
    removeAbortRequest(state, 'search');
    removeAbortRequest(state, 'searchMore');
  });
}
