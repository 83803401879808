import { deepEquals } from '../../util/deepEquals';
import { logError } from '../../util/logError';
import { getUrlPathBereich } from '../../util/pvLagenUtil';
import type { HtmlPageSublage } from '../htmlTypes';
import { isAbortError, isErrorWithStatus, postNoRedirect } from '../util/fetch';
import {
  LOAD_SUBLAGENSEITE_ERROR,
  LOAD_SUBLAGENSEITE_FINISHED,
  LOAD_SUBLAGENSEITE_STARTED,
} from '../../reducer/filterEvents';
import { scrollToTop } from '../../util/scrollIntoView';
import type { SimpleStore } from '../../state/SimpleStore';
import { abortRequestFor } from '../util/abortRequestFor';
import type { SublagenRequest } from './PvLagenRequest';
import { Screen } from '../../view';
import { handleAdditionalDataLoaded } from './handleAdditionalDataLoaded';

export function loadSublagenseiteIfNotLoading(
  simpleStore: SimpleStore,
  request: SublagenRequest,
): void {
  const state = simpleStore.getState();

  const currentLoadingForRequest = state.abortRequest.sublagenseite?.forRequest;
  if (!deepEquals(request, currentLoadingForRequest)) {
    const { abortRequest, result } = postNoRedirect<
      SublagenRequest,
      HtmlPageSublage
    >({
      url: getUrlPathBereich(state.lang, request.pv_lage),
      data: request,
      flags: state.flags,
    });
    abortRequestFor(state.abortRequest.sublagenseite);
    simpleStore.dispatch(
      LOAD_SUBLAGENSEITE_STARTED({
        abortRequest,
        forRequest: request,
      }),
    );
    result
      .then((pageResult) => {
        const latestRequest =
          simpleStore.getState().abortRequest.sublagenseite?.forRequest;
        if (deepEquals(latestRequest, request)) {
          simpleStore.dispatch(
            LOAD_SUBLAGENSEITE_FINISHED({
              patch: pageResult.page,
              screenRequest: {
                screen: Screen.Sublage,
                forRequest: { pv_lage: request.pv_lage },
              },
            }),
          );
          scrollToTop();
        }
        handleAdditionalDataLoaded(
          simpleStore,
          request.regschl,
          pageResult.additionalData,
        );
      })
      .catch((e: unknown) => {
        const latestRequest =
          simpleStore.getState().abortRequest.sublagenseite?.forRequest;
        if (deepEquals(latestRequest, request)) {
          // request war nicht erfolgreich
          if (isAbortError(e)) {
            // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
          } else if (isErrorWithStatus(e) && e.status === 404) {
            // keine Daten gefunden
            simpleStore.dispatch(
              LOAD_SUBLAGENSEITE_ERROR(new Error('Nicht gefunden')),
            );
          } else {
            // sonstiger Fehler
            logError(e);
            simpleStore.dispatch(LOAD_SUBLAGENSEITE_ERROR(e));
          }
        }
      });
  }
}
