import { Dispatch } from 'redux';
import type { ChatbotScriptSrc } from '../../constants';
import { Flags } from '../../history/flags';
import { addChatbotHeaderIfMissing } from '../../view/blocks/chatbot/addChatbotHeaderIfMissing';
import { renderChatbotNotAvailable } from '../../view/blocks/chatbot/renderChatbotNotAvailable';

const SHADOW_SELECTOR = '!SHADOW';

export async function loadAndOpenChatbot(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
  chatbotSelectors: string[],
): Promise<void> {
  const loadSuccess = await addChatbotHeaderIfMissing(
    dispatch,
    flags,
    chatbotScriptSrc,
  );
  if (loadSuccess) {
    if (chatbotSelectors.length) {
      const buttonElement = chatbotSelectors.reduce(
        (
          element: Document | Element | ShadowRoot | undefined | null,
          selector: string,
        ) => {
          if (selector === SHADOW_SELECTOR) {
            return element && 'shadowRoot' in element
              ? element.shadowRoot
              : undefined;
          } else {
            return element?.querySelector(selector);
          }
        },
        document,
      );
      if (
        buttonElement &&
        'click' in buttonElement &&
        typeof buttonElement.click === 'function'
      ) {
        buttonElement.click();
      }
    }
  } else {
    renderChatbotNotAvailable();
  }
}
