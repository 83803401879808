/* eslint-disable no-param-reassign */
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  removeAbortRequest,
  type State,
  storeAbortRequest,
} from '../state/createInitialState';
import {
  ICONS_LOADING_ERROR,
  ICONS_LOADING_FINISHED,
  ICONS_LOADING_STARTED,
} from './iconEvents';

export function createIconsReducer(builder: ActionReducerMapBuilder<State>) {
  builder.addCase(ICONS_LOADING_ERROR, (state) => {
    removeAbortRequest(state, 'icons');
    state.icons = {};
  });
  builder.addCase(ICONS_LOADING_FINISHED, (state, action) => {
    const icons = action.payload;
    removeAbortRequest(state, 'icons');
    state.icons = icons;
  });
  builder.addCase(ICONS_LOADING_STARTED, (state, action) => {
    const { abortRequest } = action.payload;
    storeAbortRequest(state, 'icons', {
      abortRequest,
      forRequest: undefined,
    });
  });
}
