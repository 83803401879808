import {
  APP_PATH_DIENSTSTELLE,
  APP_PATH_DIENSTSTELLEN,
  APP_PATH_LEISTUNG,
  APP_PATH_START,
  APP_PATH_BEREICH_BUERGER,
  APP_PATH_BEREICH_UNTERNEHMEN,
} from './constants';
import { getPvLagenLevel, PvLagenLevel } from './util/pvLagenUtil';
import { UnreachableCaseError } from './util/UnreachableCaseError';

export enum Screen {
  Bereich = 'bereich',
  Information = 'info',
  Lage = 'lage',
  Leistung = 'leistung',
  Org = 'org',
  OrgSuche = 'org_suche',
  Startseite = 'startseite',
  Sublage = 'sublage',
  Suche = 'suche',
}

export const PARAM_PV_LAGE = 'pv_lage';
export const PARAM_SEARCH_TERM = 'suche';

export function getScreenFromAppPath(
  appPath: string | undefined,
  params: URLSearchParams,
): Screen {
  if (appPath === undefined) throw new Error(`Unkown url 'undefined'`);
  let screen: Screen;
  if (appPath === APP_PATH_START) {
    screen = Screen.Startseite;
  } else if (
    appPath === APP_PATH_BEREICH_BUERGER ||
    appPath === APP_PATH_BEREICH_UNTERNEHMEN
  ) {
    const sucheParam = params.get(PARAM_SEARCH_TERM) ?? undefined;
    if (sucheParam) {
      screen = Screen.Suche;
    } else {
      const pvLageParam = params.get(PARAM_PV_LAGE) ?? undefined;
      if (!pvLageParam) {
        screen = Screen.Bereich;
      } else {
        const pvLagenLevel = getPvLagenLevel(pvLageParam);
        switch (pvLagenLevel) {
          case PvLagenLevel.BEREICH:
            screen = Screen.Bereich;
            break;
          case PvLagenLevel.LAGE:
            screen = Screen.Lage;
            break;
          case PvLagenLevel.SUBLAGE:
            screen = Screen.Sublage;
            break;
          default:
            throw new UnreachableCaseError(pvLagenLevel);
        }
      }
    }
  } else if (appPath === APP_PATH_DIENSTSTELLEN) {
    screen = Screen.OrgSuche;
  } else if (appPath === APP_PATH_LEISTUNG) {
    screen = Screen.Leistung;
  } else if (appPath === APP_PATH_DIENSTSTELLE) {
    screen = Screen.Org;
  } else {
    screen = Screen.Information;
  }
  return screen;
}
