import { patch } from 'incremental-dom';
import { button, div, h2 } from '../../../render/html';
import {
  BUTTON__SCHLIESSEN,
  CHATBOT__MODAL__NICHT_VERFUEGBAR__HEADER,
  CHATBOT__MODAL__NICHT_VERFUEGBAR__TEXT,
} from '../../../texts';

const ID_MODAL_WRAPPER = 'vwp_nfk_ask_privacy_wrapper';
const ID_MODAL = 'vwp_nfk_ask_privacy_modal';
const ID_MODAL_TITLE = 'vwp_nfk_ask_privacy_title';

export function renderChatbotNotAvailable() {
  if (!document.getElementById(ID_MODAL_WRAPPER)) {
    const outer = document.createElement('div');
    outer.id = ID_MODAL_WRAPPER;
    document.body.appendChild(outer);
    patch(
      outer,
      div(
        {
          id: ID_MODAL,
          class: 'modal fade',
          tabindex: '-1',
          'aria-labelledby': ID_MODAL_TITLE,
        },
        [
          div({ class: 'modal-dialog' }, [
            div({ class: 'modal-content' }, [
              div({ class: 'modal-header' }, [
                h2({ class: 'modal-title', id: ID_MODAL_TITLE }, [
                  CHATBOT__MODAL__NICHT_VERFUEGBAR__HEADER,
                ]),
                button({
                  type: 'button',
                  class: 'btn-close',
                  'data-bs-dismiss': 'modal',
                  'aria-label': BUTTON__SCHLIESSEN,
                }),
              ]),
              div(
                {
                  class: 'modal-body font-font-roc-grotesk-w05-regular',
                },
                [CHATBOT__MODAL__NICHT_VERFUEGBAR__TEXT],
              ),
              div({ class: 'modal-footer' }, [
                button(
                  {
                    type: 'button',
                    class: 'btn btn-secondary',
                    'data-bs-dismiss': 'modal',
                  },
                  [BUTTON__SCHLIESSEN],
                ),
              ]),
            ]),
          ]),
        ],
      ),
    );
    const modalElement = document.getElementById(ID_MODAL);
    if (modalElement) {
      const modal = new window.bootstrap.Modal(modalElement);
      modalElement.addEventListener('hidden.bs.modal', () => {
        modal.dispose();
        document.body.removeChild(outer);
      });
      modal.show();
    }
  }
}
