import type {
  Dropdown,
  DropdownOption,
} from '../../action/search/searchCommon';
import { Dropdowns } from '../../action/search/searchCommon';
import type { RenderParam } from '../../lib/renderTypes';
import {
  DROPDOWN_HIDDEN,
  DROPDOWN_SELECTED,
  DROPDOWN_SHOWN,
} from '../../reducer/events';
import { createGenerateIdToken } from '../../render/customize-incremental-dom';
import { div, i, input, li, p, ul } from '../../render/html';
import { filterUndefinedOrEmptyAttributes } from '../../util/filterUndefinedOrEmptyAttributes';
import { mapBooleanAttributes } from '../../util/mapBooleanAttributes';
import './render.css';
import type { GenerateIdToken } from '../../render/common-render-types';
import type { SimpleStore } from '../../state/SimpleStore';

export type RenderInputParams = {
  autocomplete?: string;
  id: string | GenerateIdToken;
  hasError?: boolean;
  type: string;
  value: string | undefined;
  class?: string;
  placeholder?: string;
  ariaDescription?: string;
  spellcheck?: false;
  oninput?: (event: InputEvent) => void;
  onfocus?: (event: FocusEvent) => void;
  onblur?: (event: FocusEvent) => void;
  onkeydown?: (event: KeyboardEvent) => void;
};

export type RenderDropdownParams = {
  cssClass: string;
  inputParams: RenderInputParams;
  dropdownState: Dropdown;
  datalist: DropdownOption[];
  action: (v: string) => void;
  valueError?: string;
  preListBlock?: RenderParam;
  onunhandledkeydown?: (event: KeyboardEvent) => void;
  handlers: HandlersForInputDropdown;
};

let focusTimeout: NodeJS.Timeout | undefined;

function clearFocusTimeout() {
  if (focusTimeout) {
    clearTimeout(focusTimeout);
    focusTimeout = undefined;
  }
}

export type HandlersForInputDropdown = {
  hideDropdown: () => void;
  selectDropdown: (index: number | undefined) => void;
  showDropdown: () => void;
};

export function createDefaultHandlersForInputDropdown(
  simpleStoreForHandlers: SimpleStore,
  dropdown: Dropdowns,
): HandlersForInputDropdown {
  return {
    hideDropdown: () => {
      simpleStoreForHandlers.dispatch(DROPDOWN_HIDDEN(dropdown));
    },
    selectDropdown: (index: number | undefined) => {
      simpleStoreForHandlers.dispatch(
        DROPDOWN_SELECTED({
          dropdown,
          index,
        }),
      );
    },
    showDropdown: () => {
      simpleStoreForHandlers.dispatch(DROPDOWN_SHOWN(dropdown));
    },
  };
}

export function inputDropdown({
  cssClass,
  inputParams,
  dropdownState,
  datalist,
  action,
  valueError,
  preListBlock,
  onunhandledkeydown,
  handlers,
}: RenderDropdownParams) {
  const selectedItemId =
    dropdownState.selection === -1 ? undefined : `dropdown-box-selection`;
  const showIndicator = Boolean(datalist.length);
  const showDropdown = Boolean(
    dropdownState.visible && (datalist.length || preListBlock),
  );
  // eslint-disable-next-line i18next/no-literal-string
  const visibleClass = showDropdown ? 'og-dropdown-visible' : '';

  const { value, ariaDescription, placeholder, ...inputParamsWithoutValue } =
    inputParams;

  const dropdownId = createGenerateIdToken();

  return div(
    {
      class: cssClass,
      onfocusout: (event: FocusEvent) => {
        clearFocusTimeout();
        // setImmediate, da ein blur-Event auch entstehen kann, wenn ein Element aus dem DOM entfernt wird
        // in diesem Fall entsteht das Event bereits während das Rendering stattfindet
        // Wenn dann während der Bearbeitung des Blur-Events ein neues Rendering gestartet wird, kommen die beiden
        // Renderings durcheinander
        const combobox = event.currentTarget as HTMLElement | null;
        setImmediate(() => {
          // focusout tritt auch beim Fokuswechsel innerhalb der Combobox auf, daher muss überprüft werden,
          // ob der Focus noch innerhalb der Combobox liegt
          if (!combobox || !combobox.contains(document.activeElement)) {
            // DROPDOWN_HIDDEN, wenn das Element nicht mehr im DOM ist, um den State zu korrigieren
            // ansonsten nur, wenn der Focus nicht mehr innerhalb der Combobox ist
            handlers.hideDropdown();
          }
          // inputParams.onblur auch bei entferntem Element aufrufen für mögliche Aufräumarbeiten
          if (inputParamsWithoutValue.onblur) {
            inputParamsWithoutValue.onblur(event);
          }
        });
      },
    },
    [
      input({
        key: 'input',
        ...filterUndefinedOrEmptyAttributes({
          ...inputParamsWithoutValue,
          class: `${inputParamsWithoutValue.class || ''} og-input ${
            showIndicator ? ' og-input-with-dropdown' : ''
          } position-relative`,
          placeholder,
          'aria-description': ariaDescription,
          role: 'combobox',
          'aria-autocomplete': 'list',
          'aria-expanded': showDropdown,
          'aria-controls': dropdownId,
          'aria-activedescendant': selectedItemId,
          onfocus: (event: FocusEvent) => {
            clearFocusTimeout();
            // Drop-Down-Liste erst nach kurzem Warten anzeigen, da onfocus auch dann geworfen wird, wenn ein anderes
            // Fenster selektiert war und jetzt dieses Fenster selektiert wird
            // --> wenn hierbei direkt auf einen Button unterhalb des Drop-Downs geklickt wird, kann es passieren, dass
            // sich die Drop-Down-Liste vordrängelt und den Klick abfängt
            focusTimeout = setTimeout(() => {
              handlers.showDropdown();
            }, 50);
            if (inputParamsWithoutValue.onfocus) {
              inputParamsWithoutValue.onfocus(event);
            }
          },
          oninput: (event: InputEvent) => {
            // im IE werden zusätzliche Input-Events ausgelöst wenn der Wert im Reducer gesetzt wird
            const inputElem = event.target as HTMLInputElement;
            if (document.activeElement?.id === inputElem.id) {
              if (!dropdownState.visible) {
                handlers.showDropdown();
              }
              if (dropdownState.selection !== undefined) {
                handlers.selectDropdown(/* index */ undefined);
              }
              if (inputParamsWithoutValue.oninput) {
                inputParamsWithoutValue.oninput(event);
              }
            }
          },
          onkeydown: (event: KeyboardEvent) => {
            if (
              // eslint-disable-next-line @typescript-eslint/no-deprecated
              event.keyCode === 38 /* arrowup */ ||
              // eslint-disable-next-line @typescript-eslint/no-deprecated
              event.keyCode === 40 /* arrowdown */
            ) {
              event.preventDefault();
              let index: number | undefined;
              // eslint-disable-next-line @typescript-eslint/no-deprecated
              if (event.keyCode === 38 /* space */) {
                if (dropdownState.selection === undefined) {
                  index = datalist.length - 1;
                } else if (dropdownState.selection > 0) {
                  index = dropdownState.selection - 1;
                } else {
                  index = undefined;
                }
              } else if (dropdownState.selection === undefined) {
                index = 0;
              } else if (dropdownState.selection < datalist.length - 1) {
                index = dropdownState.selection + 1;
              } else {
                index = undefined;
              }
              handlers.selectDropdown(index);
              if (index !== undefined && selectedItemId) {
                const selected = document.getElementById(selectedItemId);
                const container = selected?.parentElement?.parentElement;
                if (selected && container) {
                  scrollIfNeeded(selected, container);
                }
              }
            } else if (
              // eslint-disable-next-line @typescript-eslint/no-deprecated
              event.keyCode === 13 /* return */ &&
              dropdownState.selection !== undefined
            ) {
              event.preventDefault();
              const suggestion = datalist[dropdownState.selection];
              action(suggestion.value);
              handlers.selectDropdown(/* index */ undefined);
              // eslint-disable-next-line @typescript-eslint/no-deprecated
            } else if (event.keyCode === 27 /* escape */ && showDropdown) {
              event.preventDefault();
              handlers.hideDropdown();
            } else if (onunhandledkeydown) {
              onunhandledkeydown(event);
            }
          },
          onclick: () => {
            handlers.hideDropdown();
          },
        }),
        value: value || '',
      }),
      showIndicator
        ? i({
            key: 'indicator',
            class: 'bi bi-chevron-down og-dropdown-indicator icon',
            onclick: (event: MouseEvent) => {
              const elem = event.target as HTMLElement;
              elem.closest('input')?.focus();
            },
          })
        : undefined,
      div(
        {
          key: 'dropdownlist',
          id: dropdownId,
          class: `og-dropdown ${visibleClass}`,
          tabindex: '-1',
        },
        ul(
          { class: 'og-dropdown-list', role: 'listbox' },
          datalist.map((item, index) => {
            const isSelected: boolean = dropdownState.selection === index;
            // eslint-disable-next-line i18next/no-literal-string
            const selectionClass = isSelected ? 'og-dropdown-selection' : '';
            return li(
              mapBooleanAttributes({
                id: isSelected ? selectedItemId : undefined,
                class: `og-dropdown-list-item ${selectionClass}`,
                'aria-selected': isSelected,
                role: 'option',
                onclick: (event: MouseEvent) => {
                  event.preventDefault();
                  action(item.value);
                  handlers.selectDropdown(/* index */ undefined);
                  handlers.hideDropdown();
                },
                onmousedown: (event: Event) => {
                  event.preventDefault();
                },
              }),
              item.customLabel || item.value,
            );
          }),
        ),
      ),
      valueError ? p({ key: 'errortext' }, valueError) : undefined,
    ],
  );
}

function scrollIfNeeded(element: HTMLElement, container: HTMLElement) {
  if (element.offsetTop < container.scrollTop) {
    // eslint-disable-next-line no-param-reassign
    container.scrollTop = element.offsetTop;
  } else {
    const offsetBottom = element.offsetTop + element.offsetHeight;
    const scrollBottom = container.scrollTop + container.offsetHeight;
    if (offsetBottom > scrollBottom) {
      // eslint-disable-next-line no-param-reassign
      container.scrollTop = offsetBottom - container.offsetHeight;
    }
  }
}
