import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { div, h1, i, p } from '../../render/html';
import orgSearch, { orgResults } from '../blocks/orgSearch';
import type { SimpleStore } from '../../state/SimpleStore';
import {
  ORGSUCHE__TEXT,
  ORGSUCHE__INPUT__TITEL,
  ORGSUCHE__FILTER__ERKLAERUNG,
  ORGSUCHE__ERGEBNIS__ERKLAERUNG,
  ORGSUCHE__TITEL,
  ShareTemplate,
} from '../../texts';
import h from '../../render/incremental-hyperscript';
import { share } from '../share';
import { Icons } from '../elements/icon';
import { REGSCHL_HESSEN } from '../../constants';

export default function mainContent(
  simpleStore: SimpleStore,
  shareLinkDefinitions: ShareTemplate[],
  iconsSafeHtml: Icons,
): RenderResultWithSsr {
  const state = simpleStore.getState();

  const renderResult =
    Boolean(state.search.request.searchTerm) ||
    state.citySearch.selectedOrt.id !== REGSCHL_HESSEN;

  return orgSucheInternal(
    state.flags.share_generic === 'show',
    shareLinkDefinitions,
    iconsSafeHtml,
    [
      orgSearch(
        // eslint-disable-next-line i18next/no-literal-string
        'og-org-search',
        simpleStore,
        renderResult,
        ORGSUCHE__INPUT__TITEL,
      ),
      ...(renderResult
        ? [orgResults('', simpleStore, ORGSUCHE__ERGEBNIS__ERKLAERUNG)]
        : []),
    ],
  );
}
export function orgSucheInternal(
  showShareGeneric: boolean,
  shareLinkDefinitions: ShareTemplate[],
  iconsSafeHtml: Icons,
  content: RenderResultWithSsr[],
): RenderResultWithSsr {
  return h.fragment([
    share(
      showShareGeneric,
      {
        title: document.title,
        url: String(document.location),
      },
      shareLinkDefinitions,
      iconsSafeHtml,
    ),
    div(
      { key: 'dynamic' },
      h1({ class: 'd-flex column-gap-2 text-break' }, [
        i(
          {
            class: `bi bi-buildings icon`,
          },
          '',
        ),
        ORGSUCHE__TITEL,
      ]),
      p(
        { class: 'font-font-roc-grotesk-w05-regular' },
        ORGSUCHE__TEXT,
        ' ',
        ORGSUCHE__FILTER__ERKLAERUNG,
      ),
      // eslint-disable-next-line i18next/no-literal-string
      ...content,
    ),
  ]);
}
