import { createSelector } from 'reselect';
import type { SearchType } from '../../action/search/searchTypes';
import type { State } from '../../state/createInitialState';
import type { Screen } from '../../view';
import { Lang } from '../../util/i18n/lang';

export const selectLang = (state: State): Lang => state.lang;
export const selectScreen = (state: State): Screen => state.screen;
export const selectSearchState = (state: State): typeof state.search =>
  state.search;
export const selectSearchRequest = createSelector(
  selectSearchState,
  (searchState) => searchState.request,
);
export const selectSearchData = createSelector(
  selectSearchState,
  (searchState) => searchState.results,
);
export const selectSearchLoading = (state: State): boolean =>
  Boolean(state.abortRequest.search);
export const selectSelectedOrtId = (state: State): string =>
  state.citySearch.selectedOrt.id;

export const selectSearchDataFor = createSelector(
  selectSearchData,
  (searchData) => searchData.forRequest,
);

export const selectSearchType = createSelector(
  selectSearchData,
  (searchData): SearchType | undefined => searchData.data?.searchType,
);
