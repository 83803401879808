import { Dispatch } from 'redux';
import { showChatbotAskPrivacy } from '../../../action/chatbot/showChatbotAskPrivacy';
import type { ChatbotScriptSrc } from '../../../constants';
import { Flags } from '../../../history/flags';
import { renderChatbotButton } from './renderChatbotButton';

export function renderChatbotAskPrivacyButton(
  dispatch: Dispatch,
  flags: Flags,
  chatbotScriptSrc: ChatbotScriptSrc,
  chatbotSelectors: string[],
) {
  return renderChatbotButton(
    false,
    showChatbotAskPrivacy(dispatch, flags, chatbotScriptSrc, chatbotSelectors),
  );
}
