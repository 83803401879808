import { fetchStatistics } from '../action/util/fetchStatistics';
import { DOM_ID_SECTION_SHARE, SERVER_URL_SHARE_STATISTIC } from '../constants';
import type { RenderResultWithSsr } from '../lib/renderTypes';
import { frameSelector, patchOuterAll } from '../render/cached-incremental-dom';
import { a, button, div } from '../render/html';
import loadingOverlay from './blocks/loadingOverlay';
import {
  ShareTemplate,
  TEILEN__GENERIC__ACTION__TEXT,
  TEILEN__PLATZHALTER__TITEL,
  TEILEN__PLATZHALTER__URL,
} from '../texts';
import { UnreachableCaseError } from '../util/UnreachableCaseError';
import { Icons, renderIcon } from './elements/icon';

export type ShareParam = { title: string; url: string };

function shareGenericAction(params: ShareParam) {
  const shareData: ShareData = {
    text: TEILEN__GENERIC__ACTION__TEXT,
    title: params.title,
    url: params.url,
  };
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  navigator.share(shareData);
}

function shareGenericCheckShow(params: ShareParam) {
  if (
    !('navigator' in window) ||
    !('canShare' in navigator) ||
    !('share' in navigator)
  ) {
    return false;
  } else {
    const shareData: ShareData = {
      title: params.title,
      url: params.url,
    };
    return navigator.canShare(shareData);
  }
}

function sharePrintAction() {
  window.print();
}

export function patchShare(
  showGenericShare: boolean,
  shareLinkDefinitions: ShareTemplate[],
  iconsSafeHtml: Record<string, string> | undefined,
) {
  const shareMappings = {
    [frameSelector.share]: share(
      showGenericShare,
      {
        title: document.title,
        url: String(document.location),
      },
      shareLinkDefinitions,
      iconsSafeHtml,
    ),
  };
  patchOuterAll(shareMappings);
}

function buildShareLinks(
  showGenericShare: boolean,
  params: ShareParam,
  shareLinkDefinitions: ShareTemplate[],
  iconsSafeHtml: Icons,
) {
  return shareLinkDefinitions
    .map((template) => {
      let action: undefined | { func: () => void } | { urlTemplate: string };
      switch (template.action) {
        case 'link':
          action = { urlTemplate: template.linkUrl };
          break;
        case 'print':
          action = { func: sharePrintAction };
          break;
        case 'share':
          if (showGenericShare && shareGenericCheckShow(params)) {
            action = {
              func: () => {
                shareGenericAction(params);
              },
            };
          } else {
            action = undefined;
          }
          break;
        default:
          throw new UnreachableCaseError(template);
      }
      if (action === undefined) {
        return undefined;
      } else {
        const icon = renderIcon(iconsSafeHtml, {
          iconId: template.iconId,
          alt: template.alt,
        });
        if ('urlTemplate' in action) {
          const href = action.urlTemplate
            .replace(TEILEN__PLATZHALTER__TITEL, params.title)
            .replace(TEILEN__PLATZHALTER__URL, params.url);

          return a(
            {
              // eslint-disable-next-line i18next/no-literal-string
              target: '_blank',
              // _blank impliziert noopener, trotzdem geben wir es hier an, falls noch jemand einen alten Browser (vor ca. 2021) verwendet
              // eslint-disable-next-line i18next/no-literal-string
              rel: 'noopener external',
              href,
              class: 'vwp-icon--touch',
              onclick: attachStatisticCall(template.statisticTitle),
              onauxclick: attachStatisticCall(template.statisticTitle),
            },
            [icon],
          );
        } else {
          const actionWithStatisticCall = () => {
            attachStatisticCall(template.statisticTitle)();
            action.func();
          };
          return button(
            {
              class: 'vwp-icon--touch btn m-0 p-0',
              onclick: actionWithStatisticCall,
              onauxclick: actionWithStatisticCall,
            },
            [icon],
          );
        }
      }
    })
    .filter((value) => value !== undefined);
}

export function share(
  showGenericShare: boolean,
  // ohne params wird ein leerer Platzhalter angezeigt
  params: ShareParam | undefined,
  shareLinkDefinitions: ShareTemplate[],
  iconsSafeHtml: Icons,
): RenderResultWithSsr {
  const shareLinks = params
    ? buildShareLinks(
        showGenericShare,
        params,
        shareLinkDefinitions,
        iconsSafeHtml,
      )
    : [div({ class: 'vwp-icon--touch' })];

  return div(
    {
      class:
        'my-3 d-flex w-100 justify-content-end font-size-3 position-relative',
      id: DOM_ID_SECTION_SHARE,
    },
    [
      div({ class: 'd-flex justify-content-end position-relative flex-wrap' }, [
        ...shareLinks,
        params ? undefined : loadingOverlay(),
      ]),
    ],
  );
}

function attachStatisticCall(destination: string) {
  return () => {
    // eslint-disable-next-line no-void
    void fetchStatistics({
      url: SERVER_URL_SHARE_STATISTIC(),
      data: {
        dest: destination,
        title: document.title,
        location: document.location.toString(),
      },
    });
  };
}
