import { rawImage } from './rawImage';
import { span } from '../../render/html';
import { loadingIndicatorInline } from '../blocks/loading';

export type IconId =
  | 'envelope'
  | 'facebook'
  | 'linkedin'
  | 'share'
  | 'twitter'
  | 'xing'
  | 'printer'
  | 'buildings'
  | 'leichte_sprache'
  | 'display'
  | 'map';
export type Icons = Partial<Record<IconId, string | undefined>> | undefined;

export function renderIcon(
  iconsSafeHtml: Icons,
  {
    iconId,
    alt,
    class: className = '',
  }: { iconId: IconId; alt: string; class?: string },
) {
  if (iconsSafeHtml) {
    return rawImage({
      alt,
      class: className,
      data: iconsSafeHtml[iconId] ?? '',
    });
  } else if (alt) {
    return span(
      {
        /* Don't use aria-label or aria-labelledby on a span or div unless its given a role.
         * When aria-label or aria-labelledby are on interactive roles (such as a link or button) or an img role,
         * they override the contents of the div or span.
         * Other roles besides Landmarks (discussed above) are ignored.
         * https://www.w3.org/TR/using-aria/ */
        'aria-label': alt,
        role: 'img',
      },
      loadingIndicatorInline(),
    );
  } else {
    return loadingIndicatorInline();
  }
}
