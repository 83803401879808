import maxBy from 'lodash/fp/maxBy';
import type { Draft } from 'immer';
import type { HtmlPatch } from '../action/htmlTypes';
import type {
  HtmlCacheEntry,
  State,
  HtmlCacheEntryData,
} from '../state/createInitialState';
import { deepEquals } from '../util/deepEquals';
import { UnreachableCaseError } from '../util/UnreachableCaseError';
import { Screen } from '../view';
import { buildPvLagenRequest } from '../action/filter/buildPvLagenRequest';
import { buildLeistungsseitenRequest } from '../action/detail/loadLeistungIfNotLoading';
import { buildOrgseitenRequest } from '../action/detail/loadOrgIfNotLoading';
import type { ScreenRequest } from '../action/common/commonRequestTypes';

const CACHE_SIZE = 10;

export function getScreenRequest(state: State): ScreenRequest {
  switch (state.screen) {
    case Screen.Information:
      return {
        screen: state.screen,
        forRequest: {
          path: state.information.path,
          search: state.information.search,
        },
      };
    case Screen.Leistung: {
      const forRequest = buildLeistungsseitenRequest(state);
      return {
        screen: state.screen,
        forRequest,
      };
    }
    case Screen.Org: {
      const forRequest = buildOrgseitenRequest(state);
      return {
        screen: state.screen,
        forRequest,
      };
    }
    case Screen.OrgSuche:
    case Screen.Startseite:
      return {
        screen: state.screen,
      };
    case Screen.Bereich:
    case Screen.Lage:
    case Screen.Sublage:
      return {
        screen: state.screen,
        forRequest: buildPvLagenRequest(state),
      };
    case Screen.Suche:
      return {
        screen: state.screen,
      };
    default:
      throw new UnreachableCaseError(state.screen);
  }
}

export function getPatchToShow(state: State): {
  matches: boolean;
  cacheEntry: HtmlCacheEntryData | undefined;
} {
  const screenRequest = getScreenRequest(state);

  // passenden Eintrag zurückliefern, falls vorhanden
  const matchingEntry = state.html.find(matchesRequest(screenRequest));
  if (matchingEntry) {
    return { matches: true, cacheEntry: matchingEntry.data };
  }

  if (screenRequest.screen === Screen.Suche) {
    // Bei Suchen sollte der Rahmen von einer PV-Lage verwendet werden
    const cacheEntryPvLage = maxBy((entry: HtmlCacheEntry) => entry.lastUsed)(
      state.html.filter((entry) =>
        [Screen.Bereich, Screen.Lage, Screen.Sublage, Screen.Suche].includes(
          entry.data.screenRequest.screen,
        ),
      ),
    );
    if (cacheEntryPvLage) {
      return {
        matches: true,
        cacheEntry: {
          // Es soll die Suchseite gerendert werden, auch wenn für den Rahmen eine andere PV-Lagen-Seite verwendet wird
          patch: {
            data: { screen: Screen.Suche },
            pageElements: cacheEntryPvLage.data.patch.pageElements,
          },
          screenRequest: {
            screen: Screen.Suche,
            forRequest: undefined,
          },
        },
      };
    }
    // Wenn wir keine PV-Lagen-Seite haben, nutzen wir den Rahmen einer anderen Seite, aber ohne den mittleren Bereich (navigation, …)
    const cacheEntry = maxBy((entry: HtmlCacheEntry) => entry.lastUsed)(
      state.html,
    );
    if (cacheEntry) {
      return {
        matches: true,
        cacheEntry: {
          // Es soll die Suchseite gerendert werden, auch wenn für den Rahmen eine andere Seite verwendet wird
          patch: {
            data: { screen: Screen.Suche },
            pageElements: {
              ...cacheEntry.data.patch.pageElements,
              navigationHtml: undefined,
              asideHtml: undefined,
            },
          },
          screenRequest: {
            screen: Screen.Suche,
            forRequest: undefined,
          },
        },
      };
    } else {
      return {
        matches: false,
        cacheEntry: undefined,
      };
    }
  }
  // ansonsten aktuellsten Eintrag nutzen
  return {
    matches: false,
    cacheEntry: maxBy((entry: HtmlCacheEntry) => entry.lastUsed)(state.html)
      ?.data,
  };
}

function matchesRequest(screenRequest: ScreenRequest) {
  return (entry: HtmlCacheEntry) =>
    entry.data.screenRequest.screen === screenRequest.screen &&
    deepEquals(entry.data.screenRequest.forRequest, screenRequest.forRequest);
}

export function hasPatch(state: State, screenRequest: ScreenRequest): boolean {
  return state.html.some(matchesRequest(screenRequest));
}

export function getPatch(
  state: State,
  screenRequest: ScreenRequest,
): HtmlPatch | undefined {
  return state.html.find(matchesRequest(screenRequest))?.data.patch;
}

export function markPatchAsCurrentIfExists(draft: Draft<State>): void {
  const screenRequest = getScreenRequest(draft);
  const matching = draft.html.find(matchesRequest(screenRequest));
  if (matching) {
    // eslint-disable-next-line no-param-reassign
    matching.lastUsed = Date.now();
  }
}

function removePatch(draft: Draft<State>, screenRequest: ScreenRequest) {
  const existingIndex = draft.html.findIndex(matchesRequest(screenRequest));
  if (existingIndex >= 0) {
    draft.html.splice(existingIndex, 1);
  }
}

/**
 * Falls der Cache zu groß ist, entferne die ältesten Einträge
 */
function tidyCache(draft: Draft<State>) {
  while (draft.html.length > CACHE_SIZE) {
    let oldestTimestamp = draft.html[0].lastUsed;
    let oldestIndex = 0;
    draft.html.forEach((entry, index) => {
      if (entry.lastUsed < oldestTimestamp) {
        oldestTimestamp = entry.lastUsed;
        oldestIndex = index;
      }
    });
    draft.html.splice(oldestIndex, 1);
  }
}

export function putPatch1(draft: Draft<State>, data: HtmlCacheEntryData) {
  removePatch(draft, data.screenRequest);
  const cacheEntry: HtmlCacheEntry = {
    notFound: false,
    lastUsed: Date.now(),
    data,
  };
  draft.html.push(cacheEntry);
  tidyCache(draft);
}

export function putPatchNotFound1(
  draft: Draft<State>,
  data: HtmlCacheEntryData,
) {
  removePatch(draft, data.screenRequest);
  draft.html.push({
    notFound: true,
    lastUsed: Date.now(),
    data,
  });
  tidyCache(draft);
}
