import { SimpleStore } from '../state/SimpleStore';
import { get } from './util/fetch';
import { SERVER_URL_ICONS } from '../constants';
import { logError } from '../util/logError';
import {
  ICONS_LOADING_ERROR,
  ICONS_LOADING_FINISHED,
  ICONS_LOADING_STARTED,
} from '../reducer/iconEvents';

export function loadIconsIfNecessary(simpleStore: SimpleStore): void {
  const state = simpleStore.getState();
  if (!state.icons && !state.abortRequest.icons) {
    const { abortRequest, result } = get<
      Record<string, never>,
      Record<string, string>
    >({
      url: SERVER_URL_ICONS(),
      data: {},
      flags: state.flags,
    });
    simpleStore.dispatch(ICONS_LOADING_STARTED({ abortRequest }));
    result
      .then((data: Record<string, string>) => {
        simpleStore.dispatch(ICONS_LOADING_FINISHED(data));
      })
      .catch((e: unknown) => {
        // sonstiger Fehler
        logError(e);
        simpleStore.dispatch(ICONS_LOADING_ERROR());
      });
  }
}
