import lodashUniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';
import type { DropdownOption } from '../../action/search/searchCommon';
import type {
  SearchSuggestion,
  SearchSuggestionFromServer,
} from '../../action/search/searchTypes';
import { SearchSuggestionType } from '../../action/search/searchTypes';
import { DATA_LIST_MARKER } from '../../constants';
import { polyfillRegExp } from '../../polyfills';
import { buildSuggestionValue } from '../../util/buildSuggestionValue';
import { UnreachableCaseError } from '../../util/UnreachableCaseError';
import escapeForRegexp from '../util/escapeForRegexp';

/**
 * Find all words (bounded by non-letter) which start with the given wordPrefix in the given text
 * @param text
 * @param wordPrefix
 * @return The positions of the words
 */
function findAllWordPrefixes(text: string, wordPrefix: string): number[] {
  const escapedWord = escapeForRegexp(wordPrefix);
  // TODO: wirft Fehler im IE: Syntaxfehler in regulärem Ausdruck
  const regexpStart = polyfillRegExp(`^${escapedWord}`, 'ui');
  const regexpLater = polyfillRegExp(`[^\\p{L}\\p{N}]${escapedWord}`, 'ugi');
  const result: number[] = [];
  if (regexpStart.test(text)) {
    result.push(0);
  }

  // eslint-disable-next-line no-constant-condition,@typescript-eslint/no-unnecessary-condition
  while (true) {
    const match = regexpLater.exec(text);
    if (match == null) {
      break;
    } else {
      const position = match.index;
      regexpLater.lastIndex = position + 1;
      result.push(position + 1);
    }
  }

  return result;
}

/**
 * Searches for words (bounded by non-letter) in text which start with the given wordPrefix and returns the text before
 * (prefix) and after (suffix) the word (replacedWord).
 * If the wordPrefix isn't found, returns undefined. If the word is found more than once, it uses the word nearest the
 * given wordPosition.
 *
 * @param text The text which is to be searched for the word
 * @param wordPrefix The word (prefix) to search for
 * @param wordPosition The expected position of the word in the text (only used if the word is found more than once
 */
function splitWord(
  text: string,
  wordPrefix: string,
  wordPosition: number,
): { prefix: string; suffix: string; replacedWord: string } | undefined {
  const wordIndex: number[] = findAllWordPrefixes(text, wordPrefix);
  const positionDistances = wordIndex
    .map((idx) => [idx, Math.abs(idx - wordPosition)])
    .sort((entry) => entry[1]);
  if (positionDistances.length) {
    const idx = positionDistances[0][0];
    const replacedWordArray = polyfillRegExp(
      `${escapeForRegexp(wordPrefix)}[\\p{L}\\p{N}]*`,
      'ui',
    ).exec(text.substring(idx));
    if (!replacedWordArray) {
      throw new Error("unexpectedly didn't find word in text");
    }
    const replacedWord = replacedWordArray[0];
    return {
      prefix: text.substring(0, idx),
      replacedWord,
      suffix: text.substring(idx + replacedWord.length),
    };
  } else {
    return undefined;
  }
}

export function buildSuggestions(
  suggestionsFromServer: SearchSuggestionFromServer[],
  term: string,
  position: number,
): SearchSuggestion[] {
  return suggestionsFromServer.map(({ value, type, key, places }) => ({
    value,
    type,
    key,
    places,
    term,
    position,
  }));
}

let singletonWordSplitRegexp: ReturnType<typeof polyfillRegExp> | undefined;

export function wordSplitRegexp() {
  if (!singletonWordSplitRegexp) {
    singletonWordSplitRegexp = polyfillRegExp('[^\\p{L}\\p{N}]+', 'u');
  }
  return singletonWordSplitRegexp;
}

type ExtractDropDownOptionsParams = {
  searchTerm: string;
  suggestions: SearchSuggestion[];
};
export const extractDropDownOptions: (
  params: ExtractDropDownOptionsParams,
) => DropdownOption[] = createSelector(
  (params: ExtractDropDownOptionsParams) => params.searchTerm,
  (params: ExtractDropDownOptionsParams) => params.suggestions,
  (searchTermFull, suggestionData): DropdownOption[] => {
    const searchTerm = searchTermFull.trim();
    const builtSuggestions = suggestionData.flatMap((suggestion) =>
      buildSuggestion(searchTerm, suggestion),
    );
    // Begriffe vom Server sind eindeutig, aber durch die Kombination mit
    // Präfix/Suffix kann es zu nicht eindeutigen Werten kommen
    return lodashUniqBy(builtSuggestions, (sug) => sug.value).map((entry) => ({
      datalistLabel: entry.datalistLabel ?? entry.value,
      value: entry.value + DATA_LIST_MARKER,
    }));
  },
);

export function buildSuggestion(
  searchTerm: string,
  suggestion: SearchSuggestion,
): DropdownOption[] {
  const split = splitWord(searchTerm, suggestion.term, suggestion.position);
  if (!split) {
    return [];
  }
  const { prefix, replacedWord, suffix } = split;

  const { type: suggestionType, value } = suggestion;
  let replacement: string | undefined;
  let text;
  switch (suggestionType) {
    case SearchSuggestionType.WORD: {
      const wordsSuggest = value.split(wordSplitRegexp());
      if (wordsSuggest.length > 1) {
        const prefixWords = prefix
          .split(wordSplitRegexp())
          .filter((word) => word.length > 0 && !wordsSuggest.includes(word));
        const suffixWords = suffix
          .split(wordSplitRegexp())
          .filter((word) => word.length > 0 && !wordsSuggest.includes(word));
        replacement = [...prefixWords, ...wordsSuggest, ...suffixWords].join(
          ' ',
        );
      } else if (value.startsWith(replacedWord)) {
        // Schlage das Wort nur vor, wenn das Wort noch zu der (mittlerweile ggf. längeren Eingabe) passt
        replacement = prefix + value + suffix;
      } else {
        replacement = undefined;
      }
      break;
    }
    case SearchSuggestionType.DIENSTSTELLE:
    case SearchSuggestionType.INFO:
    case SearchSuggestionType.LEISTUNG:
      replacement = buildSuggestionValue(suggestion);
      break;
    default:
      throw new UnreachableCaseError(suggestionType);
  }
  if (replacement) {
    if (!replacement.toLowerCase().startsWith(searchTerm.toLowerCase())) {
      text = `${searchTerm} 🠊 ${replacement}`;
    }
    return [{ datalistLabel: text, value: replacement }];
  } else {
    return [];
  }
}
