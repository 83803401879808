import { clearSearch } from '../../action/search/clearSearch';
import {
  handleSearchChanged,
  handleSearchSet,
  handleStartpageSearchChanged,
  handleStartpageSearchSet,
} from '../../action/search/handleSearchChanged';
import type {
  Dropdown,
  DropdownOption,
} from '../../action/search/searchCommon';
import { Dropdowns } from '../../action/search/searchCommon';
import { searchFromInput } from '../../action/search/searchFromInput';
import type { SearchSuggestion } from '../../action/search/searchTypes';
import type { RenderParam, RenderResultWithSsr } from '../../lib/renderTypes';
import {
  DROPDOWN_HIDDEN,
  SCREEN_CHANGED,
  VALIDATION_ERRORS_SHOWN,
} from '../../reducer/events';
import { createGenerateIdToken } from '../../render/customize-incremental-dom';
import { div, label } from '../../render/html';
import {
  validateSearchInput,
  ValidationResult,
} from '../../validation/validateInput';
import { Screen } from '../../view';
import {
  createDefaultHandlersForInputDropdown,
  inputDropdown,
} from '../elements/renderDropdown';
import { inputButton } from '../elements/renderInput';
import { extractDropDownOptions } from '../read/extractDropDownOptions';
import {
  foundText,
  legend,
  renderResultsContent,
  renderResultsLoading,
  results,
} from './results';
import { loadDataIfNecessary } from '../../action/loadDataIfNecessary';
import { SearchResultType } from '../../action/search/searchTypes';
import {
  BUTTON__EINGABE_LEEREN,
  SUCHE__BUTTON__ARIA,
  SUCHE__INPUT__LABEL,
  SUCHE__INPUT__PLATZHALTER_BUERGER,
  SUCHE__INPUT__PLATZHALTER_UNTERNEHMEN,
} from '../../texts';
import { PV_LAGE_BUERGER, PV_LAGE_UNTERNEHMEN } from '../../util/pvLagenUtil';
import type { SimpleStore } from '../../state/SimpleStore';
import { pvLagenNavigationForSearch } from '../read/extractDataForPvLagenNavigation';
import { Icons } from '../elements/icon';

type PvLagenSearchFieldParams = {
  bereich?: string;
  dropdownState: Dropdown;
  isStartpage: boolean;
  inputValue: string;
  showValidationErrors: boolean;
  suggestions: SearchSuggestion[];
};
export function renderPvLagenSearchField(
  simpleStoreForHandlers: SimpleStore,
  params: PvLagenSearchFieldParams,
) {
  const {
    bereich,
    dropdownState,
    inputValue,
    isStartpage,
    showValidationErrors,
    suggestions,
  } = params;
  const requestFields: ValidationResult<'searchTerm'> = validateSearchInput({
    input: { searchTerm: inputValue },
    showValidationErrors,
  });
  const dataListContent = extractDropDownOptions({
    searchTerm: inputValue,
    suggestions,
  });

  let inputDescription;

  if (isStartpage && bereich) {
    if (bereich === PV_LAGE_BUERGER) {
      inputDescription = SUCHE__INPUT__PLATZHALTER_BUERGER;
    } else if (bereich === PV_LAGE_UNTERNEHMEN) {
      inputDescription = SUCHE__INPUT__PLATZHALTER_UNTERNEHMEN;
    }
  }

  return searchField({
    simpleStoreForHandlers,
    value: requestFields.searchTerm.value,
    valueError: requestFields.searchTerm.error,
    dropdownState,
    datalist: dataListContent,
    inputDescription,
    oninput: (event: InputEvent) => {
      handleStartpageSearchChanged(simpleStoreForHandlers, event.target);
    },
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    dropdownSelect: (v: string) =>
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleStartpageSearchSet(simpleStoreForHandlers, v),
    onButtonClick: (event: MouseEvent) => {
      event.preventDefault();
      const currentState = simpleStoreForHandlers.getState();
      // speziell auf der Startseite kann das Input-Feld auch gefüllt sein, wenn ein Suchbegriff eingegeben ist
      // (auf den PV-Lagenseiten wird in diesem Fall auf die Suchseite gewechselt)
      if (currentState.search.request.searchTerm) {
        simpleStoreForHandlers.dispatch(DROPDOWN_HIDDEN(Dropdowns.SEARCH));
        simpleStoreForHandlers.dispatch(DROPDOWN_HIDDEN(Dropdowns.CITY));
        simpleStoreForHandlers.dispatch(SCREEN_CHANGED(Screen.Suche));
        loadDataIfNecessary(simpleStoreForHandlers);
      } else {
        simpleStoreForHandlers.dispatch(VALIDATION_ERRORS_SHOWN());
      }
    },
    additionalClass: isStartpage ? 'mt-2' : '',
  });
}

export function renderSearchPageSearchField(
  simpleStore: SimpleStore,
  labelText?: string,
  description?: string,
) {
  const state = simpleStore.getState();
  const { request, showValidationErrors } = state.search;
  const { searchTerm } = request;
  const requestFields = validateSearchInput({
    input: { searchTerm },
    showValidationErrors,
  });
  const dataListContent = extractDropDownOptions({
    searchTerm,
    suggestions: state.search.suggestionData,
  });

  return searchField({
    simpleStoreForHandlers: simpleStore,
    // KEEP_VALUE funktioniert hier nicht, wenn bei lokalen Tests die Suchseite direkt vom Server kommt, da in diesem Fall der Suchbereich nicht vorgerendert wird
    // TODO: auch bei lokalen Tests hier die richtige Seite holen
    value: requestFields.searchTerm.value,
    valueError: requestFields.searchTerm.error,
    dropdownState: state.dropdowns[Dropdowns.SEARCH],
    datalist: dataListContent,
    labelText,
    oninput: (event: InputEvent) => {
      handleSearchChanged(simpleStore, event.target);
    },
    dropdownSelect: (v: string) => {
      handleSearchSet(simpleStore, v);
    },
    onButtonClick: (event) => {
      event.preventDefault();
      searchFromInput(simpleStore);
    },
    inputDescription: description,
    additionalClass: '',
  });
}

function searchField({
  simpleStoreForHandlers,
  value,
  valueError,
  dropdownState,
  datalist,
  inputDescription,
  labelText = SUCHE__INPUT__LABEL,
  oninput,
  dropdownSelect,
  onButtonClick,
  additionalClass,
}: {
  simpleStoreForHandlers: SimpleStore;
  value: string;
  valueError?: string;
  dropdownState: Dropdown;
  datalist: DropdownOption[];
  inputDescription?: string;
  labelText?: string;
  bereichSelection?: RenderResultWithSsr;
  oninput: (event: InputEvent) => void;
  dropdownSelect: (v: string) => void;
  onButtonClick: (event: MouseEvent) => void;
  additionalClass: string;
}) {
  const inputId = createGenerateIdToken();
  return div(
    {
      class: `d-flex flex-column og-search-field-box font-font-roc-grotesk-w05-regular ${additionalClass}`,
    },
    [
      label(
        {
          for: inputId,
          class: 'mb-1',
        },
        labelText,
      ),
      div({ class: 'd-flex' }, [
        inputDropdown({
          cssClass: 'og-flex-grow-shrink',
          inputParams: {
            id: inputId,
            type: 'search',
            autocomplete: 'off',
            ariaDescription: inputDescription,
            placeholder: inputDescription,
            value,
            class: valueError ? 'og-error' : undefined,
            oninput,
          },
          handlers: createDefaultHandlersForInputDropdown(
            simpleStoreForHandlers,
            Dropdowns.SEARCH,
          ),
          dropdownState,
          datalist,
          action: dropdownSelect,
          valueError,
        }),
        inputButton({
          ariaLabel: SUCHE__BUTTON__ARIA,
          iconClass: 'bi-search',
          class: 'text-dark btn btn-outline-primary ms-0',
          onclick: onButtonClick,
          type: 'submit',
        }),
        inputButton({
          ariaLabel: BUTTON__EINGABE_LEEREN,
          iconClass: 'bi-trash3',
          class: 'text-dark btn btn-outline-primary ms-0',
          onclick: (event: Event) => {
            event.stopPropagation();
            clearSearch(simpleStoreForHandlers);
          },
          type: 'button',
        }),
      ]),
    ],
  );
}

export function searchResults(
  simpleStore: SimpleStore,
  iconsSafeHtml: Icons,
): RenderParam {
  const state = simpleStore.getState();
  const searchResultLoading = Boolean(state.abortRequest.search);
  const searchMoreResultLoading = Boolean(state.abortRequest.searchMore);

  const content = renderResultsContent(
    simpleStore,
    state.flags.show === 'score',
    /* showTitle */ true,
    /* shouldRenderAskList */ true,
  );

  let loading: RenderResultWithSsr[] = [];
  if (searchResultLoading || searchMoreResultLoading) {
    const dataDisplayed = Boolean(content.length);
    loading = renderResultsLoading(dataDisplayed);
  }

  return results(
    simpleStore,
    searchResultsMeta(simpleStore, iconsSafeHtml),
    /* showTitle */ true,
    content,
    loading,
  );
}

export function searchResultsMeta(
  simpleStore: SimpleStore,
  iconsSafeHtml: Icons,
): RenderResultWithSsr | undefined {
  const state = simpleStore.getState();
  const { data, forRequest } = state.search.results;

  if (
    state.error.search ||
    !forRequest ||
    !data ||
    data.type === SearchResultType.MESSAGE
  ) {
    return undefined;
  }

  return div(
    {},
    foundText(simpleStore, data, forRequest),
    legend(iconsSafeHtml),
  );
}

export function searchResultsNavigation(
  simpleStore: SimpleStore,
): RenderResultWithSsr[] {
  return pvLagenNavigationForSearch(simpleStore);
}
