import { focusSeiteninhalt } from '../action/util/focusSeiteninhalt';
import { SCROLLED_TO_ANCHOR } from '../reducer/events';
import type { SimpleStore } from '../state/SimpleStore';

export function scrollToAnchor(simpleStore: SimpleStore): void {
  const state = simpleStore.getState();
  if (
    window.location.hash &&
    window.location.hash !== '#' &&
    state.scrollToAnchorNeeded
  ) {
    const anchor = document.querySelector<HTMLElement>(
      decodeURIComponent(window.location.hash),
    );
    if (anchor) {
      anchor.scrollIntoView();
      anchor.focus();
      simpleStore.dispatch(SCROLLED_TO_ANCHOR());
      // hightlight the anchored element
      const cssClassList = anchor.classList;
      // eslint-disable-next-line i18next/no-literal-string
      cssClassList.add('og-highlight-this');
      setTimeout(() => {
        // eslint-disable-next-line i18next/no-literal-string
        cssClassList.remove('og-highlight-this');
      }, 2000);
      return;
    }
  }
  focusSeiteninhalt();
}

export function scrollToTop(): void {
  window.scrollTo({ top: 0 });
}
