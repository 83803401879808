import { button, div, img } from '../../../render/html';
import { mapBooleanAttributes } from '../../../util/mapBooleanAttributes';
import { chatbotButtonImageData } from './chatbotButtonData';

export function renderChatbotButton(
  disabled: boolean,
  onclick: undefined | ((this: HTMLElement) => void),
  buttonSrc?: string,
) {
  return div({ class: 'vwp-chatbot-button-wrapper' }, [
    button(
      mapBooleanAttributes({
        class: 'vwp-chatbot-button',
        disabled,
        onclick,
      }),
      [
        img({
          class: 'w-100 h-100',
          alt: window.hzd_og_data?.datenschutz.chatbotButtonAlt,
          src:
            buttonSrc ??
            (window.hzd_og_data?.datenschutz.chatbotButtonSrc ||
              chatbotButtonImageData),
        }),
      ],
    ),
  ]);
}
