import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { div, img, p } from '../../render/html';
import { COMMON__FEHLER } from '../../texts';

export default function error(
  text: string,
  cssClass = '',
): RenderResultWithSsr {
  return div(
    {
      key: 'errorBlock',
      class: `box box--error box-margin--medium layout-image-text ${cssClass}`,
      role: 'alert',
    },
    [
      div(
        { class: 'layout-image-text__image' },
        img({
          alt: COMMON__FEHLER,
          // TODO: Bootstrap/Custom Icon von Drupal
          // src: '',
          class: 'img-icon--error icon',
        }),
      ),
      p({ class: 'layout-image-text__text text' }, text),
    ],
  );
}
