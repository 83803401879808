import { Lang } from './lang';
import { SERVER_ROOT } from '../../constants';
import { UnreachableCaseError } from '../UnreachableCaseError';

const PATH_PREFIX_DE = '';
const PATH_PREFIX_EN = 'en/';

function stripServerRoot(path: string): string {
  const serverRoot = SERVER_ROOT();
  if (path.startsWith(serverRoot)) {
    return path.substring(serverRoot.length);
  } else {
    return path;
  }
}

function langToPathPrefix(lang: Lang) {
  switch (lang) {
    case 'de_DE':
      return PATH_PREFIX_DE;
    case 'en':
      return PATH_PREFIX_EN;
    default:
      throw new UnreachableCaseError(lang);
  }
}
function pathToLang(path: string): { lang: Lang; appPath: string } {
  if (path.startsWith(PATH_PREFIX_EN)) {
    return { lang: 'en', appPath: path.substring(PATH_PREFIX_EN.length) };
  } else {
    return { lang: 'de_DE', appPath: path };
  }
}

export function getLangAndAppPathFromUrlPath(url: string): {
  lang: Lang;
  appPath: string;
} {
  return pathToLang(stripServerRoot(url));
}

export function getUrlPath(lang: Lang, appPath: string): string {
  return SERVER_ROOT() + langToPathPrefix(lang) + appPath;
}
