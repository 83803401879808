import { createSelector } from 'reselect';
import {
  selectLang,
  selectScreen,
  selectSearchRequest,
  selectSelectedOrtId,
} from './selectors';
import {
  isRegschlFilterType,
  isRegschlLevel,
  RegschlFilterType,
  RegschlLevel,
  type SearchFilterType,
  type SearchRequest,
} from '../../action/search/searchTypes';
import { Screen } from '../../view';
import { TypeFilter } from '../../constants';
import { trimAndShorten } from '../../action/util/trimAndShorten';
import { defaultOrgSearchRegschlFilter } from '../../reducer/resetOrgSearchRegschlFilter';
import type { State } from '../../state/createInitialState';
import type { Flags } from '../../history/flags';

const selectFlags = (state: State): Flags => state.flags;

export const extractSearchRequest = createSelector(
  selectFlags,
  selectLang,
  selectScreen,
  selectSelectedOrtId,
  selectSearchRequest,
  defaultOrgSearchRegschlFilter,
  (
    flags,
    lang,
    screen,
    regschl,
    stateSearchRequest,
    defaultFilter,
  ): SearchRequest => {
    const { regschlWahl } = stateSearchRequest;
    const regschlFilterTypes =
      stateSearchRequest.regschlFilterTypes ?? defaultFilter;

    let filter: SearchFilterType;
    let gebietsebene: RegschlLevel[];
    let regschlSuchTyp: RegschlFilterType[];
    if (screen === Screen.OrgSuche) {
      filter = {
        type: [TypeFilter.orgeinheit],
      };
      if (!regschlFilterTypes.length && flags.org_filter === 'single') {
        gebietsebene = Object.values(RegschlLevel);
        regschlSuchTyp = [];
      } else if (!regschlFilterTypes.length && flags.org_filter === 'dual') {
        gebietsebene = [];
        regschlSuchTyp = Object.values(RegschlFilterType);
      } else {
        gebietsebene = regschlFilterTypes.filter((filterType) =>
          isRegschlLevel(filterType),
        );
        regschlSuchTyp = regschlFilterTypes.filter((filterType) =>
          isRegschlFilterType(filterType),
        );
      }
    } else {
      filter = stateSearchRequest.filter;
      gebietsebene = [];
      regschlSuchTyp = [];
    }

    const searchTerm = trimAndShorten(stateSearchRequest.searchTerm);

    return {
      filter,
      lang,
      regschl,
      gebietsebene,
      regschlSuchTyp,
      regschlWahl,
      searchTerm,
    };
  },
);
