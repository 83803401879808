import { Dispatch } from 'redux';
import type { RenderResultWithSsr } from '../../../lib/renderTypes';
import { div } from '../../../render/html';
import { FEEDBACK__BUTTON_TEXT } from '../../../texts';
import { Screen } from '../../../view';
import type { NfkScriptSrc, NfkSettings } from '../../../constants';
import type { HtmlCacheEntryData } from '../../../state/createInitialState';
import { NfkState } from '../../../state/createInitialState';
import { Flags } from '../../../history/flags';
import { renderFeedbackButton } from './renderFeedbackButton';
import { renderFeedbackAskPrivacyButton } from './renderFeedbackAskPrivacyButton';
import { addFeedbackHeaderIfMissing } from './addFeedbackHeaderIfMissing';
import { renderFeedbackNotAvailable } from './renderFeedbackNotAvailable';

/**
 * Den Feedback-Button rendern. Unterscheidet auch, ob bereits eine Einwilligung eingeholt wurde.
 *
 * @param {Dispatch} dispatch Redux-Dispatch-Funktion
 * @param {Flags} flags Anwendungs-Flags
 * @param {HtmlCacheEntryData} cacheEntry Hieraus werden die dynamischen Attribute für den Feedback-Button extrahiert
 * @param {NfkScriptSrc | undefined} nfkScriptSrc DOM-ID und https-Adresse für das externe Feedback-Script
 * @param {NfkSettings} nfkSettings konfigurierte Attribute für den Feedback-Button
 * @param {NfkState} nfkState Zustand der NFK-Komponente
 * @returns {RenderResultWithSsr | undefined} HTML-Funktion
 */
export function renderFeedback(
  dispatch: Dispatch,
  flags: Flags,
  cacheEntry: HtmlCacheEntryData,
  nfkScriptSrc: NfkScriptSrc | undefined,
  nfkSettings: NfkSettings,
  nfkState: NfkState,
): RenderResultWithSsr | undefined {
  if (nfkScriptSrc) {
    // Nur anzeigen, wenn aktuell eine Leistung angezeigt wird.
    // In diesem Fall sind in cacheEntry.patch.data.leistung die für die
    // Feedback-Komponente nötigen Daten enthalten
    switch (cacheEntry.patch.data.screen) {
      case Screen.Leistung: {
        const { leistung } = cacheEntry.patch.data;
        return renderFeedbackWrapper(
          checkPrivacySettingsAndRenderFeedbackButton(
            dispatch,
            flags,
            leistung,
            nfkScriptSrc,
            nfkSettings,
            nfkState,
          ),
        );
      }
      default:
        return undefined;
    }
  } else {
    return undefined;
  }
}

/** Erzeugt den Event-Handler für den Feedback-Button */
function createHandleFeedbackButtonClicked(
  dispatch: Dispatch,
  flags: Flags,
  nfkScriptSrc: NfkScriptSrc,
) {
  return async function handleFeedbackButtonClicked(this: HTMLElement) {
    const loadSuccess = await addFeedbackHeaderIfMissing(
      dispatch,
      flags,
      nfkScriptSrc,
    );
    if (loadSuccess) {
      window.bmi115WidgetButton.init(this as HTMLButtonElement);
    } else {
      renderFeedbackNotAvailable();
    }
  };
}

/** Prüft auf vorhandene Einwilligung und rendert den passenden Button */
function checkPrivacySettingsAndRenderFeedbackButton(
  dispatch: Dispatch,
  flags: Flags,
  leistung: {
    id?: string;
    regschl?: string;
    firstLeika?: string;
  },
  nfkScriptSrc: NfkScriptSrc,
  nfkSettings: NfkSettings,
  nfkState: NfkState,
): RenderResultWithSsr[] | undefined {
  if (nfkState.privacyAccepted) {
    return renderFeedbackButton(
      leistung,
      nfkSettings,
      nfkState,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      createHandleFeedbackButtonClicked(dispatch, flags, nfkScriptSrc),
    );
  } else {
    return renderFeedbackAskPrivacyButton(dispatch);
  }
}

/** HTML-Rahmen zur Positionierung des Feedback-Buttons */
export function renderFeedbackWrapper(
  content: RenderResultWithSsr[] | undefined,
): RenderResultWithSsr | undefined {
  return content
    ? div(
        {
          class: 'position-fixed bottom-0 end-0 me-3 mb-md-3',
          role: 'region',
          'aria-label': FEEDBACK__BUTTON_TEXT,
        },
        content,
      )
    : undefined;
}
