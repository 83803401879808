import { createEvent, TriggeredBy } from './createEvent';

export const ICONS_LOADING_STARTED = createEvent<{
  abortRequest: () => void;
}>('ICONS_LOADING_STARTED', TriggeredBy.Other);
export const ICONS_LOADING_ERROR = createEvent(
  'ICONS_LOADING_ERROR',
  TriggeredBy.Other,
);
export const ICONS_LOADING_FINISHED = createEvent<Record<string, string>>(
  'ICONS_LOADING_FINISHED',
  TriggeredBy.Other,
);
