import sortBy from 'lodash/fp/sortBy';
import { searchMore } from '../../../action/search/searchMore';
import type {
  SearchResultData,
  SearchResultEditorial,
  SearchResultLeistung,
  SearchResultOrgeinheit,
  SearchType,
} from '../../../action/search/searchTypes';
import {
  SearchResultType,
  SearchTypeEnum,
} from '../../../action/search/searchTypes';
import { REGSCHL_HESSEN, TypeFilter } from '../../../constants';
import type {
  RenderElement,
  RenderResultWithSsr,
} from '../../../lib/renderTypes';
import { button, div, h2, i, li, ol, p, span } from '../../../render/html';
import { UnreachableCaseError } from '../../../util/UnreachableCaseError';
import { Screen } from '../../../view';
import {
  linkInternInline,
  linkInternStandalone,
} from '../../elements/linkInternInline';
import { buildLeistungUrl, buildOrgUrl } from '../../util/navUrls';
import type { ListElement, ResultRow } from './resultTypes';
import { ResultRowType } from './resultTypes';
import type { SimpleStore } from '../../../state/SimpleStore';
import {
  SUCHERGEBNIS__ANSPRECHPARTNER_VORHANDEN,
  SUCHERGEBNIS__LEICHTE_SPRACHE_VERFUEGBAR,
  SUCHERGEBNIS__ONLINE_DIENST_VERFUEGBAR,
  SUCHERGEBNIS__NICHT_GEFUNDEN,
  SUCHERGEBNIS__ORTSANGABE_NOETIG,
  SUCHERGEBNIS__ALLE_BEHOERDEN_ANZEIGEN,
  SUCHERGEBNIS__ALLE_INFORMATIONEN_ANZEIGEN,
  SUCHERGEBNIS__ALLE_LEISTUNGEN_ANZEIGEN,
  SUCHERGEBNIS__TITEL_BEHOERDEN,
  SUCHERGEBNIS__TITEL_INFORMATIONEN,
  SUCHERGEBNIS__TITEL_LEISTUNGEN,
} from '../../../texts';
import { Icons, renderIcon } from '../../elements/icon';

export function resultListMulti({
  orgAriaHint,
  showTitle,
  simpleStore,
  searchResults,
  showPreviewOnly,
  showScore,
  searchType,
}: {
  orgAriaHint: string | undefined;
  showTitle: boolean;
  simpleStore: SimpleStore;
  searchTerm: string | undefined;
  loadingMore: boolean;
  searchResults:
    | { type: SearchResultType.MESSAGE; message: string }
    | {
        type: SearchResultType.RESULT;
        result: SearchResultData;
        message: string | undefined;
      };
  showPreviewOnly: {
    editorialPages: boolean;
    leistungen: boolean;
    orgeinheiten: boolean;
  };
  showScore: boolean;
  searchType: SearchType;
}): (RenderResultWithSsr | undefined)[] {
  if (searchResults.type === SearchResultType.MESSAGE) {
    return [p({ class: 'paragraph' }, searchResults.message)];
  } else {
    const state = simpleStore.getState();

    const { icons } = state;

    let regschl: string;
    switch (searchType.type) {
      case SearchTypeEnum.CITY:
        regschl = searchType.city.id;
        break;
      case SearchTypeEnum.HESSEN:
      default:
        regschl = REGSCHL_HESSEN;
        break;
    }
    let type;
    switch (state.screen) {
      case Screen.OrgSuche:
        type = [TypeFilter.orgeinheit];
        break;
      case Screen.Bereich:
      case Screen.Lage:
      case Screen.Sublage:
      case Screen.Suche:
        type = state.search.request.filter.type;
        break;
      case Screen.Leistung:
      case Screen.Org:
      case Screen.Startseite:
      case Screen.Information:
        // no results
        return [];
      default:
        throw new UnreachableCaseError(state.screen);
    }

    const searchResultData = searchResults.result;

    const searchLeistungen =
      type.length === 0 || type.includes(TypeFilter.leistung);
    const leistungenRows = searchLeistungen
      ? buildResultBlock(
          simpleStore,
          icons,
          showScore,
          searchResultData.leistungenRows,
          searchResultData.count.leistungen,
          searchResultData.hasMoreLeistungen,
          showPreviewOnly.leistungen,
          SUCHERGEBNIS__ALLE_LEISTUNGEN_ANZEIGEN,
          // eslint-disable-next-line i18next/no-literal-string
          'bi-list-ul',
          ResultRowType.leistung,
          TypeFilter.leistung,
          regschl,
          /* title */ showTitle ? SUCHERGEBNIS__TITEL_LEISTUNGEN : '',
          /* titleAriaHint */ undefined,
        )
      : undefined;

    const searchEditorial = type.length === 0 || type.includes(TypeFilter.info);
    const editorialRows = searchEditorial
      ? buildResultBlock(
          simpleStore,
          icons,
          showScore,
          searchResultData.editorialRows,
          searchResultData.count.editorialPages,
          searchResultData.hasMoreEditorial,
          showPreviewOnly.editorialPages,
          SUCHERGEBNIS__ALLE_INFORMATIONEN_ANZEIGEN,
          // eslint-disable-next-line i18next/no-literal-string
          'bi-info-square',
          ResultRowType.editorial,
          TypeFilter.info,
          regschl,
          /* title */ showTitle ? SUCHERGEBNIS__TITEL_INFORMATIONEN : '',
          /* titleAriaHint */ undefined,
        )
      : undefined;

    const searchOrgeinheiten =
      type.length === 0 || type.includes(TypeFilter.orgeinheit);
    const orgeinheitenRows = searchOrgeinheiten
      ? buildResultBlock(
          simpleStore,
          icons,
          showScore,
          searchResultData.orgeinheitenRows,
          searchResultData.count.orgeinheiten,
          searchResultData.hasMoreOrgeinheiten,
          showPreviewOnly.orgeinheiten,
          SUCHERGEBNIS__ALLE_BEHOERDEN_ANZEIGEN,
          // eslint-disable-next-line i18next/no-literal-string
          'bi-buildings',
          ResultRowType.orgeinheit,
          TypeFilter.orgeinheit,
          regschl,
          /* title */ showTitle ? SUCHERGEBNIS__TITEL_BEHOERDEN : '',
          orgAriaHint,
        )
      : undefined;

    return [leistungenRows, editorialRows, orgeinheitenRows];
  }
}

const PREVIEW_COUNT = 5;

function buildResultBlock(
  simpleStore: SimpleStore,
  icons: Icons,
  showScore: boolean,
  data:
    | SearchResultLeistung[]
    | SearchResultEditorial[]
    | SearchResultOrgeinheit[]
    | undefined,
  count: number | undefined,
  hasMore: boolean,
  showPreviewOnly: boolean,
  textAlleXObjekte: (count: number) => string,
  iconClass: string,
  rowType: ResultRowType,
  filterType: TypeFilter,
  regschl: string,
  title: string | undefined,
  titleAriaHint: string | undefined,
) {
  if (data) {
    const showMoreButton =
      hasMore || (showPreviewOnly && data.length > PREVIEW_COUNT);

    const dataToShow = showPreviewOnly
      ? data.slice(0, Math.min(data.length, PREVIEW_COUNT))
      : data;

    const ariaLableProp = titleAriaHint ? { ariaLabel: titleAriaHint } : {};
    return div({ class: 'mb-6' }, [
      title
        ? h2(
            {
              class: 'h3 search-result-entry-title paragraph pb-2',
              ...ariaLableProp,
            },
            [i({ class: `bi ${iconClass} og-result-icon icon` }), title],
          )
        : undefined,
      ...(count
        ? [
            ol(
              { class: 'list-unstyled m-0' },
              dataToShow.map((row, index) =>
                buildRow(
                  simpleStore,
                  icons,
                  showScore,
                  {
                    type: rowType,
                    ...row,
                  } as ResultRow,
                  index,
                  regschl,
                ),
              ),
            ),
            showMoreButton
              ? button(
                  {
                    class:
                      'd-flex w-100 bg-transparent border-primary-light border-start-0 border-end-0 border-bottom-0 p-0 align-items-center font-font-roc-grotesk-w05-regular',
                    onclick: (event: MouseEvent) => {
                      event.preventDefault();
                      searchMore(simpleStore, filterType);
                    },
                  },
                  i({
                    class: `bi bi-plus bg-primary-light og-w-rem-3 me-3 icon`,
                  }),
                  span(
                    { class: 'col text-align-left' },
                    textAlleXObjekte(count),
                  ),
                )
              : undefined,
          ]
        : [
            p(
              { class: 'font-font-roc-grotesk-w05-regular' },
              SUCHERGEBNIS__NICHT_GEFUNDEN,
            ),
          ]),
    ]);
  } else {
    return undefined;
  }
}

function buildRow(
  simpleStore: SimpleStore,
  icons: Icons,
  showScore: boolean,
  row: ResultRow,
  index: number,
  regschl: string,
) {
  const idOrLocation = 'id' in row ? row.id : row.location;
  const key = `${row.type}-${idOrLocation}`;
  const rowScoreHint = showScore ? ` (${row.doc.score})` : '';
  return li(
    {
      class: `list-plain__item search-result-entry m-0 border-top d-flex flex-column ${
        index ? '' : 'search-result-entry--first'
      }`,
      key,
    },
    [
      span(
        {
          class:
            'og-result-entry-container font-font-roc-grotesk-w05-regular pt-2 pb-2',
        },
        [
          row.gueltig
            ? undefined
            : // nicht übersetzt, da nur für technische Nutzer
              // eslint-disable-next-line i18next/no-literal-string
              span({ class: 'og-invalid-result' }, 'Ungültig - '),
          buildRowLink(
            row,
            getRowLinkTitle(row) + rowScoreHint,
            simpleStore,
            regschl,
            true,
          ),
          buildContentSnippet(row),
        ],
      ),
      ...getResultRowIcons(row, icons),
    ],
  );
}

export function getResultRowIcons(
  row: ResultRow,
  icons: Icons,
): RenderElement[] {
  return row.type === ResultRowType.leistung
    ? [
        div(
          {
            class: `mb-2 d-flex`,
          },
          row.onlinedienst
            ? i(
                {
                  class: `bi bi-display icon me-2`,
                },
                span(
                  {
                    class: 'visually-hidden',
                  },
                  [SUCHERGEBNIS__ONLINE_DIENST_VERFUEGBAR],
                ),
              )
            : '',
          row.ansprechpartner
            ? i(
                {
                  class: `bi bi-buildings icon me-2`,
                },
                span(
                  {
                    class: 'visually-hidden',
                  },
                  [SUCHERGEBNIS__ANSPRECHPARTNER_VORHANDEN],
                ),
              )
            : '',
          row.ortNotwendig
            ? i(
                {
                  class: `bi bi-map icon me-2`,
                },
                span(
                  {
                    class: 'visually-hidden',
                  },
                  [SUCHERGEBNIS__ORTSANGABE_NOETIG],
                ),
              )
            : '',
          row.leichteSprache
            ? renderIcon(icons, {
                iconId: 'leichte_sprache',
                alt: SUCHERGEBNIS__LEICHTE_SPRACHE_VERFUEGBAR,
              })
            : '',
        ),
      ]
    : [];
}

type Param<T extends ListElement> = {
  data: T[];
  hasMore: boolean;
};

export function buildContentSnippet(row: ResultRow): RenderElement {
  let snippet: string | undefined;
  switch (row.type) {
    case ResultRowType.editorial:
      snippet = row.description;
      break;
    case ResultRowType.leistung:
    case ResultRowType.orgeinheit:
      snippet = row.contentSnippet;
      break;
    default:
      throw new UnreachableCaseError(row);
  }

  return snippet
    ? p({ class: 'search-result-entry-content text mb-0' }, [
        ...(row.type === ResultRowType.leistung
          ? [
              span(
                { class: 'search-result-entry-header paragraph' },
                row.leikaLeistung,
              ),
              ' - ',
            ]
          : []),
        snippet,
        // ' ',
        // buildRowLink(row, 'mehr', simpleStore, regschl, false),
      ])
    : undefined;
}

export function buildRowLink(
  row: ResultRow,
  linkTitle: string,
  simpleStore: SimpleStore,
  regschl: string,
  /** true for building linkInternStandalone, false for building linkInternInline */
  standalone: boolean,
): RenderResultWithSsr {
  let url;
  switch (row.type) {
    case ResultRowType.editorial:
      url = row.location;
      break;
    case ResultRowType.leistung:
      url = buildLeistungUrl({
        leistung_id: row.id,
        regschl: row.validForRegschluessel ?? regschl,
        simpleStore,
      });
      break;
    case ResultRowType.orgeinheit:
      url = buildOrgUrl({ org_id: row.id, simpleStore });
      break;
    default:
      throw new UnreachableCaseError(row);
  }
  if (standalone) {
    return linkInternStandalone(
      {
        href: url,
        class: 'og-result-title',
      },
      linkTitle,
    );
  } else {
    return linkInternInline(
      {
        href: url,
        class: 'og-result-title',
      },
      linkTitle,
    );
  }
}

export function getRowLinkTitle(row: ResultRow): string {
  switch (row.type) {
    case ResultRowType.editorial:
      return row.title;
    case ResultRowType.leistung:
      return row.bezeichnungPlain;
    case ResultRowType.orgeinheit:
      return row.bezeichnung;
    default:
      throw new UnreachableCaseError(row);
  }
}

export function mixResults<T extends ListElement>(lists: Param<T>[]): T[] {
  const withLastScores = lists.map((param) => ({
    data: param.data,
    hasMore: param.hasMore,
    lastScore: param.data.length
      ? // der letzte Score wird zum Filtern der anderen Listen genutzt
        param.data[param.data.length - 1].doc.score
      : // eine leere Liste schränkt die Anzeige anderer Listen nicht ein
        Number.NEGATIVE_INFINITY,
  }));
  const filteredLists = lists.map((param, index) => {
    const { data } = param;
    // Die Einträge der Listen "links" von dieser Liste werden bei gleichem Score vorrangig angezeigt.
    // Das bedeutet, dass Elemente dieser Liste mit gleichem Score noch nicht angezeigt werden dürfen, da beim Nachladen
    // ggf. in den "linken" Listen weitere Elemente mit gleichem Score nachgeladen werden können
    const greater = Math.max(
      // wenn Math.max ohne Argumente aufgerufen wird, ist das Ergebnis -Infinity
      ...withLastScores
        .slice(0, index)
        // berücksichtige nur die Listen, bei denen noch Daten nachgeladen werden können
        .filter((entry) => entry.hasMore)
        .map((entry) => entry.lastScore),
    );
    // Die Einträge der Listen "rechts" von dieser Liste werden bei gleichem Score nachrangig angezeigt.
    // Das bedeutet, dass Elemente dieser Liste mit gleichem Score bereits angezeigt werden dürfen
    const greaterOrEqual = Math.max(
      // wenn Math.max ohne Argumente aufgerufen wird, ist das Ergebnis -Infinity
      ...withLastScores
        .slice(index + 1, withLastScores.length)
        // berücksichtige nur die Listen, bei denen noch Daten nachgeladen werden können
        .filter((entry) => entry.hasMore)
        .map((entry) => entry.lastScore),
    );
    return /* filteredData = */ data.filter(
      (entry) => entry.doc.score > greater && entry.doc.score >= greaterOrEqual,
    );
  });

  return /* allRowsSorted = */ sortBy((entry: T) => -entry.doc.score)(
    ([] as T[]).concat(...filteredLists),
  );
}
