import type { Gebiet } from '../../action/search/searchTypes';
import { SELECTED_ORT_HESSEN } from '../../constants';
import {
  GEBIETSANGABE__GEMEINDEVERBAND_PRAEFIX,
  GEBIETSANGABE__KREIS_PRAEFIX,
} from '../../texts';

export default function combinePlaceNames(place: Gebiet): string {
  if (place.ort) {
    const kreisText = place.kreis
      ? [`${GEBIETSANGABE__KREIS_PRAEFIX} ${place.kreis}`]
      : [];
    const landText = place.ort.includes(place.land) ? [] : [place.land];
    let explanationText = [...kreisText, ...landText].join(', ');
    explanationText = explanationText ? ` (${explanationText})` : '';
    return `${place.ort}${explanationText}`;
  } else if (place.verband) {
    const kreisText = place.kreis
      ? `${GEBIETSANGABE__KREIS_PRAEFIX} ${place.kreis}, `
      : '';
    return `${GEBIETSANGABE__GEMEINDEVERBAND_PRAEFIX} ${place.verband} (${kreisText}${place.land})`;
  } else if (place.kreis) {
    return `${GEBIETSANGABE__KREIS_PRAEFIX} ${place.kreis} (${place.land})`;
  } else if (place.bezirk) {
    return `${place.bezirk} (${place.land})`;
  } else {
    return place.land;
  }
}
export function combinePlaceNamesForInput(place: Gebiet): string {
  if (place.id === SELECTED_ORT_HESSEN.id) {
    return '';
  } else {
    return combinePlaceNames(place);
  }
}
export function simplePlaceName(place: Gebiet): string {
  if (place.ort) {
    return place.ort;
  } else if (place.verband) {
    return `${GEBIETSANGABE__GEMEINDEVERBAND_PRAEFIX} ${place.verband}`;
  } else if (place.kreis) {
    return `${GEBIETSANGABE__KREIS_PRAEFIX} ${place.kreis}`;
  } else if (place.bezirk) {
    return place.bezirk;
  } else {
    return place.land;
  }
}
