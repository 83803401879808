import last from 'lodash/fp/last';
import { SERVER_URL_SUCHE_MEHR, TypeFilter } from '../../constants';
import { deepEquals } from '../../util/deepEquals';
import { logError } from '../../util/logError';
import { get, isAbortError, isErrorWithStatus } from '../util/fetch';
import {
  SEARCH_MORE_ERROR,
  SEARCH_MORE_FINISHED,
  SEARCH_MORE_NOT_FOUND,
  SEARCH_MORE_STARTED,
} from './searchEvents';
import type { SearchMoreRequest, SearchMoreResult } from './searchTypes';
import { SearchResultType } from './searchTypes';
import type { SimpleStore } from '../../state/SimpleStore';
import { abortRequestFor } from '../util/abortRequestFor';
import { extractSearchRequest } from '../../view/read/extractSearchRequest';

export function searchMore(simpleStore: SimpleStore, type: TypeFilter): void {
  const state = simpleStore.getState();
  const forRequest = extractSearchRequest(state);
  const searchState = state.search;
  const { data } = searchState.results;

  if (!data) {
    throw new Error(
      'Cannot search for more if there is no current search result',
    );
  }

  if (data.type === SearchResultType.MESSAGE) {
    throw new Error(
      'Cannot search for more if the previous search resulted in an error condition',
    );
  }

  const { editorialRows, leistungenRows, orgeinheitenRows } = data.result;

  const lastKnownEditorial = last(editorialRows);
  const lastKnownLeistungen = last(leistungenRows);
  const lastKnownOrgeinheiten = last(orgeinheitenRows);
  const lastKnownDocLeistungen = lastKnownLeistungen
    ? lastKnownLeistungen.doc
    : { score: undefined, doc: undefined, shardIndex: undefined };
  const lastKnownDocOrgeinheiten = lastKnownOrgeinheiten
    ? lastKnownOrgeinheiten.doc
    : { score: undefined, doc: undefined, shardIndex: undefined };
  const lastKnownDocEditorial = lastKnownEditorial
    ? lastKnownEditorial.doc
    : { score: undefined, doc: undefined, shardIndex: undefined };

  const request = {
    lang: forRequest.lang,
    suche: forRequest.searchTerm,
    regschl: forRequest.regschl,
    pv_lage: forRequest.filter.pvLage,
    typ: [type],
    gebietsebene: forRequest.gebietsebene,
    regschl_suchtyp: forRequest.regschlSuchTyp,
    leist_score: lastKnownDocLeistungen.score,
    leist_doc: lastKnownDocLeistungen.doc,
    leist_shard: lastKnownDocLeistungen.shardIndex,
    org_score: lastKnownDocOrgeinheiten.score,
    org_doc: lastKnownDocOrgeinheiten.doc,
    org_shard: lastKnownDocOrgeinheiten.shardIndex,
    editorial_score: lastKnownDocEditorial.score,
    editorial_doc: lastKnownDocEditorial.doc,
    editorial_shard: lastKnownDocEditorial.shardIndex,
  };

  if (!deepEquals(state.abortRequest.searchMore?.forRequest, request)) {
    const { abortRequest, result } = get<SearchMoreRequest, SearchMoreResult>({
      url: SERVER_URL_SUCHE_MEHR(),
      data: request,
      flags: state.flags,
    });
    simpleStore.dispatch(
      SEARCH_MORE_STARTED({ abortRequest, forRequest: request }),
    );
    abortRequestFor(state.abortRequest.searchMore);
    result
      .then((results: SearchMoreResult) => {
        const latestRequest =
          simpleStore.getState().abortRequest.searchMore?.forRequest;
        if (deepEquals(latestRequest, request)) {
          simpleStore.dispatch(
            SEARCH_MORE_FINISHED({ searchType: type, results }),
          );
        }
      })
      .catch((e: unknown) => {
        const latestRequest =
          simpleStore.getState().abortRequest.searchMore?.forRequest;
        if (deepEquals(latestRequest, request)) {
          // request war nicht erfolgreich
          if (isAbortError(e)) {
            // Request wurde absichtlich (abortRequest()) abgebrochen --> nichts zu tun
          } else if (isErrorWithStatus(e) && e.status === 404) {
            // keine Daten gefunden
            simpleStore.dispatch(SEARCH_MORE_NOT_FOUND(type));
          } else {
            // sonstiger Fehler
            logError(e);
            simpleStore.dispatch(SEARCH_MORE_ERROR(e));
          }
        }
      });
  }
}
