import type { RenderResultWithSsr } from '../../lib/renderTypes';
import { span } from '../../render/html';
import { DANGEROUSLY_HTML_CONTENT } from '../../render/incremental-hyperscript';
import './rawImage.css';

export function rawImage({
  alt = '',
  class: className = '',
  data,
  onclick,
}: {
  alt?: string;
  class?: string;
  data: string;
  onclick?: ((event: MouseEvent) => void) | (() => void);
}): RenderResultWithSsr {
  return span({
    class: className,
    [DANGEROUSLY_HTML_CONTENT]: data,
    onclick,
    /* Don't use aria-label or aria-labelledby on a span or div unless its given a role.
     * When aria-label or aria-labelledby are on interactive roles (such as a link or button) or an img role,
     * they override the contents of the div or span.
     * Other roles besides Landmarks (discussed above) are ignored.
     * https://www.w3.org/TR/using-aria/ */
    'aria-label': alt,
    role: alt !== '' ? 'img' : 'presentation',
  });
}
